@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");

.font_100x {
  font-size: 80px;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -55%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 0%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}
