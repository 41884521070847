.logo_prod {
    transition: transform 330ms ease-in-out;
}

.logo_prod:hover {
    transform: scale(1.1);
    transition: transform 330ms ease-in-out;
}

.centerText {
    
    justify-content:center;
    align-items:center;
    text-align: center;
    
}

.card__title{
    color: var(--body_color);
}
