body {
    color: var(--body_color);

}

.blog_container {
    padding-top: 90px;
    
}
.blog_container:hover {
    cursor: pointer;
}

.font_60x {
    font-size: 60px;
    line-height: 1.2;
}
.gapleft{
    margin-left:5px;
}

@media (min-width: 992px) {
    .blog_Responsive .col-lg-6 {
        flex: 0 0 auto;
        width: calc(50% - 10px);
        
    }
}

/* .blog_Responsive .col-lg-6 {
    flex: 0 0 auto;
    width: calc(50% - 10px);
} */

.font_48x {
    font-size: 48px;
    line-height: 1.2;
}

.font_30x {
    font-size: 30px;
    line-height: 1.2;
}

.font_23x {
    font-size: 23px;
    color: var(--body_color) !important;
}

.font_20x {
    font-size: 20px;
    color: var(--body_color) !important;
}

/* .blog_flipicon_image {
    flex-basis: calc(62% - 20px);
    background: #E6E6E6;


} */

/* .blog_flipicon_image img {
    position: absolute;
    transform: translatex(50%);
    bottom: 0px;
} */

/* .blog_flipicon_image_content {
    flex-basis: calc(38% - 20px);
    color: #5A5858;

} */

.flex-b-50 {
    flex-basis: calc(50% - 20px);
}

.border-1x {
    /* border: 1px solid rgba(0, 109, 255, 0.5); */
    border: 1px solid var(--blog-border-orange) !important;

}

.border-1x.orange {
    border: 1px solid var(--blog-border-orange) !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-b-3x {
    margin-bottom: 30px;
}

.margin-tb-2x {
    margin-top: 20px;
    margin-bottom: 20px;
}

.padding-0 {
    padding: 0 0;
}

@media (max-width: 767px) {

    .blog_container .font_23x, .blog_container .default-link.font_20x, .blog_container .font_20x {
        font-size: 15px !important;


    }

    .blog_container .font_40x {

        font-size: 25px !important;
        text-align: center;
    }

    .blog_container {
        /* padding-top: 90px; */
        margin: 0 19px 19px;
    }

    .blog_container .border-1x.orange, .blog_container .border-1x {

        border: 0 !important;
    }

    .font_60x {
        font-size: 35px;

    }
}