.section2-container {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: -80px;
  flex-direction: column;
}

.section2-table {
  width: 100%;
  background-color: var(--body_background);
  border-radius: 6px;
  border: 1px solid var(--table-border);
  /*  */
  /* margin-top: 45vh; */
}

.table-row {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.table-header-element {
  color: var(--table_header);
  font-family: AvenirRegular, serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  width: 25%;
}

.no-border {
  border: none !important;
}

.coinName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coin-initials {
  color: var(--body_color);
  padding: 0 6px;
}

.price {
  color: var(--body_color);
}

.hourChange {
  color: #006dff;
}

.all_markets {
  text-align: center;
  padding: 50px 0;
}

.view_all_btn {
  height: 60px;
  border-radius: 0;
  color: var(--body_color) !important;
  border-color: #006dff;
  background-color: var(--body_background) !important;
}

@media (max-width: 767px) {
  .table-header-element {
    text-align: center;
  }

  .table-row {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }

  .table-row > div {
    margin-right: 10px;
  }

  .coinName span {
    display: none;
  }

  .section2-table {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .chart-icon {
    max-width: 98px;
  }
}
