.small-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.airdrop_banner {
  background-image: url("../../../assets/banners/Airdrop\ Banner\ 02\ \(1\).png");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}

.ch-airdrop_banner {
  background-image: url("../../../assets/banners/first.svg");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}

.sb-airdrop_banner {
  background-image: url("../../../assets/banners/second.svg");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}
.bitcoinnew_banner {
  background-image: url("../../../assets/banners/bitcoin.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 635px;

  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
  margin-top: -110px;
}

.ferrari_banner {
  background-image: url("../../../assets/banners/ferrari.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 0.99;
  height: 680px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}
.get_free_token {
  background-image: url("../../../assets/banners/get_free_tokens_bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 0.99;
  height: 730px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  /* padding-bottom: 80px; */
  /* margin-top: -210px; */
}
.crypto_carnival {
  background-image: url("../../../assets/banners/crypto_carnival.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  /* padding-bottom: 80px; */
  /* margin-top: -210px; */
}

.giftcard_banner {
  background-image: url("../../../assets/banners/gftbanner.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}

.greeting_banner {
  background-image: url("../../../assets/banners/grt\ banner\ 1.svg");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.035;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: left;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding-bottom: 80px;
}
.yellow_bg_btn {
  background: #ffb300;
  color: #000;
  font-size: 16px;
  padding: 15px 65px;
}
.yellow_bg_btn:hover {
  color: #000;
  opacity: 0.9;
}
.wallstreet_banner {
  background-image: url("../../../assets/banners/new_wallstreet.svg");
  margin-top: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  gap: 60px;
  padding-bottom: 112px;
}

.hive_banner {
  background-image: url("../../../assets/banners/hive.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 60px;
  padding-left: 312px;
  padding-bottom: 132px;
}

.crypto_banner {
  background-image: url("../../../assets/banners/white-1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding-bottom: 100px;
}

.bitcoin_banner {
  background-image: url("../../../assets/banners/white-2.svg");
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding-bottom: 172px;
}

.greet_banner {
  background-image: url("../../../assets/banners/black-1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 60px;
  padding-bottom: 150px;
}

.xnft_banner {
  background-image: url("../../../assets/banners/black-2.svg");
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding-bottom: 172px;
}

.airdrop-link-1 {
  z-index: 100000;
  color: black;
  margin-left: -1087px;
  cursor: pointer;
}
.airdrop-link-2 {
  z-index: 100000;
  color: white;
  margin-left: 607px;
  cursor: pointer;
}

.airdrop-link-1:hover {
  color: #fff;
}
.airdrop-link-2:hover {
  color: #000;
}

.gift-link {
  color: black;
  margin-left: -8px;
}

.gift-link:hover {
  color: #616161;
}

.register-link {
  color: black;
  margin-left: -70px;
}
.register-links {
  color: black;
}
.register-linkss {
  color: black;
}
.register-linksss {
  color: white;
}

.register-link:hover {
  color: #616161;
}

.greeting-link {
  color: black;
  background-color: #ffb300;
  padding: 10px 20px;
  margin-left: -420px;

  position: relative;
  top: -180px; /* Adjust the value to move it upwards */
  z-index: 999; /* Set a higher value if needed */
}

.greeting-link:hover {
  color: black;
  background-color: #ffd000;
}

.wall-link {
  color: black;
}

.wall-link:hover {
  color: #616161;
}

.hive-link {
  color: #ffb300;
}

.hive-link:hover {
  color: #ff9d00;
}

.exch-link {
  color: #0ea55a;
}

.exch-link:hover {
  color: #11be6a;
}

.login-button-banner {
  border-bottom: 50px solid #00a150;
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}
.earnbutton {
  background-color: #00a150;
  border-radius: 0px;
  height: 35px;
  font-size: 13px;
}
.earnbutton:hover {
  background-color: #028342;
}

.login-button-banner:hover {
  border-bottom-color: #028342;
  border-left-color: transparent;
}

.launch-app-button-banner {
  border-top: 50px solid #00a150;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.launch-app-button-banner:hover {
  border-top: 50px solid #028342;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.trade_right {
  padding-bottom: 35px;
}

.line_height_1 {
  line-height: 1;
}

.mega_heading {
  font-size: 80px;
  font-family: AvenirMedium;
}

.flex-direction-row {
  flex-direction: row;
}

.text-green {
  color: #8be64b;
}

.tm_text {
  font-size: 15px;
  position: relative;
  bottom: 0;
  text-align: center;
}

.trade_percentage {
  font-family: AvenirHeavy;
  font-size: 130px;
  margin-top: 20px;
}

.earn_btn {
  font-size: 22px;
  min-height: 43px;
  margin: 35px auto 0;
}

.ant-btn.btn-success.earn_btn {
  max-width: 325px;
}
.bitcoin_banner_root {
  margin-top: 150px;

  z-index: 11;
}

.inexIcon {
  justify-content: flex-end;
}
@media (max-width: 767px) {
  html,
  body {
    overflow-x: hidden;
  }
  .bitcoin_banner_root {
    margin-top: 150px;
    flex-direction: column;
  }
  .bitcoinIcon {
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .inexIcon {
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    order: 0;
  }
  .inex_left {
    order: 2;
  }
  .small-container {
    flex-direction: column;
    text-align: center;
  }

  .airdrop-link {
    margin-left: 88px;
  }

  .register-links {
    font-size: 10px;
  }
  .register-linkss {
    font-size: 10px;
  }
  .bitcoinnew_banner {
    background-image: url("../../../assets/banners/bitcoin.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    padding-bottom: 80px;
  }

  .ferrari_banner {
    background-image: url("../../../assets/banners/ferrari.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    padding-bottom: 80px;
  }
  .get_free_token {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    padding-bottom: 80px;
  }
  .crypto_carnival {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    padding-bottom: 80px;
  }

  .yellow_bg_btn {
    background: #ffb300;
    color: #000;
    font-size: 14px;
    padding: 10px 45px;
  }
  .giftcard_banner {
    margin-top: 20px;
    width: 100%;
    height: 205px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    gap: 30px;
    padding-bottom: 22px;
  }

  .airdrop_banner {
    margin-top: 20px;
    width: 100%;
    height: 205px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    gap: 30px;
    padding-bottom: 22px;
  }

  .greeting-link {
    padding: 5px;
    font-size: 10px;
  }

  .greeting_banner {
    background-image: url("../../../assets/banners/grt\ banner\ 1.svg");
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    scale: 1.035;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: left;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    padding-bottom: 80px;
  }
  .wallstreet_banner {
    margin-top: 30px;
    width: 100%;
    height: 228px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    gap: 30px;
    padding-bottom: 22px;
  }

  .hive_banner {
    width: 100%;
    height: 248px;
    background-position: center 100%;
    background-image: url("../../../assets/banners/hive-mobile.svg");
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;

    gap: 30px;
    padding-left: 0px;
    padding-bottom: 22px;
  }

  .crypto_banner {
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    margin-bottom: 20px;

    gap: 30px;
    padding-bottom: 47px;
  }

  .bitcoin_banner {
    margin-bottom: 20px;
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    gap: 30px;
    padding-bottom: 94px;
  }

  .greet_banner {
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    margin-bottom: 20px;
    justify-content: flex-end;

    gap: 20px;
    padding-bottom: 80px;
  }

  .xnft_banner {
    margin-bottom: 20px;
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;

    gap: 30px;
    padding-bottom: 96px;
  }

  .ant-btn.btn-success.earn_btn {
    max-width: calc(100% - 30px);
  }

  .tm_text {
    font-size: 7px;
  }

  .mega_heading.text-white {
    font-size: 25px !important;
  }

  .mega_heading.trade_percentage .mega_heading.trade_percentage span {
    font-size: 30px !important;
  }

  .trade_right {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1400px) {
  .my-container {
    max-width: 1420px;
  }
}
