@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mansalva&display=swap);
:root {
  --body_background: #fff;
  --body_color: #5f5f5f;
  --disabled-btn: 0, 0, 0;
  --link_color: #2607f3;
  --table_header: #939292;
  --table-border: #e6e8ea;
  --section3-unselected: rgba(0, 0, 0, 0.15);
  --blog-border-orange: rgba(246, 96, 54, 0.2);
  --market-table-color: #fff;
  --border-color: #d9d9d9;
  --card-color: #fff;
  --dark_text: #000;
  --timer-color: #000000;
  --calendar-disabled: #e6e6e6;
  --cal-bg: #eaf6ff;
}

[data-bs-theme="dark"] {
  --body_background: #000;
  --body_color: #e0e0e0;
  --disabled-btn: 255, 255, 255;
  --link_color: #f307b8;
  --table_header: #aeaeae;
  --table-border: #3c3b3b;
  --section3-unselected: rgba(255, 255, 255, 0.25);
  --blog-border-orange: rgba(59, 130, 246, 0.3);
  --market-table-color: #575757;
  --border-color: #282828;
  --card-color: #282828;
  --dark_text: #fff;
  --timer-color: #2d2d2d;
  --calendar-disabled: #2c2c2c;
  --cal-bg: #263e51;
}
/* * {
  transition: all 0.2s ease-in-out;
} */

@font-face {
  font-family: AvenirLight;
  src: url(/static/media/AvenirLight.4343d6ec.ttf) format("truetype");
}

@font-face {
  font-family: AvenirRegular;
  src: url(/static/media/AvenirRoman.0bfb049d.ttf) format("truetype");
}

@font-face {
  font-family: AvenirMedium;
  src: url(/static/media/AvenirMedium.adcbfbb8.ttf) format("truetype");
}

@font-face {
  font-family: AvenirHeavy;
  src: url(/static/media/AvenirBlack.7ce894e1.ttf) format("truetype");
}

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body_background);
  color: var(--body_color);
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body_color);
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.mb-0 {
  margin-bottom: 0;
}

.card {
  min-width: 420px;
  background-color: var(--body_background);
  border: 1px solid rgba(0, 109, 255, 0.5);
  min-height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.card__header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.5);
}

.card__header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__header__inner__left {
  flex: 1 1;
}

.card__header__inner__right {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.card__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card__footer {
  text-align: center;
  padding: 20px;
}

.card__title {
  color: var(--body_color) !important;
  font-size: 40px;
  margin-bottom: 0;
  flex: 1 1;
  line-height: 1.2;
}

.orange.card {
  border: 1px solid rgba(246, 96, 54, 0.3);
}

.tag {
  font-size: 1em;
  font-family: AvenirHeavy;
  border-radius: 10px;
  padding: 5px 10px 2px;
  background-color: var(--body_background);
  color: var(--body_color);
}

.input__field {
  border: 1px solid rgba(0, 109, 255, 0.5);
  border-radius: 5px;
  height: 56px;
  line-height: 60px;
}

.input__field input {
  text-align: right;
  font-size: 25px;
  padding-right: 30px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  display: none;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.index_tokens img {
  width: 176px;
  height: 176px;
}

.font_25x {
  font-size: 25px;
}

.modal-backdrop {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 5px;

  max-width: 80%;
}

.close-btn-modl {
  position: absolute;
  width: 5%;
  top: -15px;
  right: 110px;
  background: none;
  /* border: 1px solid; */
  font-size: 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}
.close-btn-modl:hover {
  transform: scale(1.1);
}
.modal-body {
  /* Add your modal body styles here */
  position: relative;
  border: "1px solid red";
  height: 420px;
}

.modl-btn {
  position: absolute;
  width: 220px;
  height: 41px;
  border-radius: 0;
  transition: all 0.1s ease;
}
.modl-btn:hover {
  transform: scale(1.015);
}

.mod-green-2 {
  left: 550px;
  bottom: 10px;
}
.mod-green-1 {
  left: 100px;
  bottom: 10px;
}
.mod-blue {
  right: 100px;
  bottom: 10px;
  background: #004dcd;
}
.modals-container {
  display: flex; /* Set display to flex */
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.modals-item {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; /* Adjust width for web view */
}
.discountImage {
  background-image: url(/static/media/discountHero.38dbd587.svg);
  width: 100%;
  max-width: 1920px;
  margin: auto;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
}

.discountCard_root {
  max-width: 400px;
  margin: auto;
  margin-bottom: 100px;
  height: 100%;
}

/*  */
.card {
  width: 300px;
  height: 400px;
  background-color: blue;
}
.card-back {
  background-color: red;
}
/*  */

@media screen and (max-width: 768px) {
  .discountCard_root {
    margin-left: auto;
    margin-right: auto;
  }
}

.discountCard_border {
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.discountCard_header {
  background: #ffb300;
  color: #000;
  font-weight: bold;
  font-size: 26px;
  padding: 10px;
  text-align: center;
}
.detailed_section {
  height: 390px;
}
.detailed_text {
  font-size: 17px;
  color: #000;
  text-align: center;
}

.discountCard_mainText {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  color: #000;
}
.discountCard_cryptoImg_container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.discountCard_cryptoImg {
}
.discountCard_price {
  font-size: 24px;
  font-weight: bolder;
  color: #000;
  text-align: center;
}

@media (max-width: 768px) {
  /* Adjust breakpoint for small screens */
  .modals-item {
    width: 100%; /* Stacks items on small screens */
  }
}

.modals-image {
  width: 100%;
  height: 100%;
  /* Ensure images fill their container */
}

.modals-button {
  display: block;
  background: #0155ee;
  width: 220px;
  border-radius: 0;
  height: 41px;
  margin: -10px auto 30px;
}
.modals-button:hover {
  background: #1b6cff;
}
.modals-button-blue {
  display: block;
  width: 220px;
  border-radius: 0;
  height: 41px;
  margin: 20px auto;
  background: #004dcd;
}
.modals-button-blue:hover {
  transform: scale(1.015);
}

@media (max-width: 990px) {
  .close-btn-modl {
    top: -45px;
    right: 0px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-select-arrow {
  height: 20px;
  font-size: 19px;
  transform: rotate(-90deg);
  color: #5f5f5f;
}

.pointer-events-none {
  pointer-events: none;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.home-containers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background-color: var(--body_background);
  padding-bottom: 150px;
}

.home-container-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  flex-direction: column;
  overflow: hidden;
}

.center-Image {
  height: 136px;
  /* margin-top: -130px; */
  margin-top: 50px;
}

.section-heading {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 25px;
  text-align: center;
  margin-top: -40px;
}

.section-helper-text {
  font-family: AvenirMedium, serif;
  font-size: 15px;
  color: #d9d9d9;
  padding: 10px 20px 25px;
  text-align: center;
  /* margin-top: -30px; */
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.big_trade_font {
  font-size: 120px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
}

.cut_button {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  cursor: pointer;
  text-align: center;
}

.login-button {
  border-bottom: 50px solid #11be6a;
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}

.login-button:hover {
  border-bottom-color: #0ea55a;
  border-left-color: transparent;
}

.launch-app-button {
  border-top: 50px solid #006dff;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.launch-app-button:hover {
  border-top: 50px solid #00387e;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.desc {
  color: white;
  margin-top: 20px; /* Adjust as needed */
  text-align: center;
  font-size: 14px; /* Adjust as needed */
  display: block;
}

.abc {
  flex: 1 1;
  margin-bottom: 20px; /* Add spacing between rows */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.4s ease-in-out;
}

/* .abc:hover {
  transform: scale(1.15);
} */

.launch-app-button-text {
  margin-bottom: 48px;
}

.login-button_text {
  margin-top: 48px;
}

.home-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 70px 10px;
  width: 100%;
  max-width: 1280px;
}
.home-main-container-left {
  flex: 1 1;
}
.signInHover:hover {
  border: 1px solid #ffb300;
  background: none !important;
  color: white !important;
}
.signOutHover:hover {
  background: #ffb300;
  color: black !important;
}
.home-main-container-left h1 {
  font-size: 78px;
  line-height: 98px;
  color: #d1d1d1;
  width: 85%;
  margin-bottom: 50px;
}
.home-main-container-left h3 {
  font-size: 22px;
  color: #d1d1d1;
}
.home-main-container-right {
  flex: 1 1;
}
.home-main-container-right p {
  font-size: 36px;
  color: #d1d1d1;
  font-style: italic;
}
.home-main-container-right-new p {
  font-size: 28px;
  color: #d1d1d1;
}
/* Responsive design for tablet and mobile */
@media (max-width: 768px) {
  .home-main-container {
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    text-align: center;
    justify-content: center;
  }
  .home-main-container-left h1 {
    width: 100%;
  }
  .home-main-container-right {
    width: 100%;
  }
}

.td-none {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .desc2 {
    display: none !important;
  }
  .abc {
    margin-bottom: 50px;
  }
  .section-heading {
    color: white;
    font-family: AvenirMedium, serif;
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
  }
}

/* .desc {
  opacity: 1;
  color: white;
} */

/* .abc:hover .desc {
  opacity: 1;
  color: white;
} */

.desc2 {
  display: block;
  opacity: 0;
  font-size: 12px;
  color: white !important;
  margin-top: 5px;
  padding: 5px;
}

.abc:hover .desc2 {
  opacity: 1;
}

.enlarge:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

/* Add the below CSS to remove scaling when not on hover */
.enlarge {
  transition: transform 330ms ease-in-out;
}

.abc {
  text-align: center;
}

/* .desc {
  color: white;
  margin-top: 5px;
} */

.section2-container {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: -80px;
  flex-direction: column;
}

.section2-table {
  width: 100%;
  background-color: var(--body_background);
  border-radius: 6px;
  border: 1px solid var(--table-border);
  /*  */
  /* margin-top: 45vh; */
}

.table-row {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.table-header-element {
  color: var(--table_header);
  font-family: AvenirRegular, serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  width: 25%;
}

.no-border {
  border: none !important;
}

.coinName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coin-initials {
  color: var(--body_color);
  padding: 0 6px;
}

.price {
  color: var(--body_color);
}

.hourChange {
  color: #006dff;
}

.all_markets {
  text-align: center;
  padding: 50px 0;
}

.view_all_btn {
  height: 60px;
  border-radius: 0;
  color: var(--body_color) !important;
  border-color: #006dff;
  background-color: var(--body_background) !important;
}

@media (max-width: 767px) {
  .table-header-element {
    text-align: center;
  }

  .table-row {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }

  .table-row > div {
    margin-right: 10px;
  }

  .coinName span {
    display: none;
  }

  .section2-table {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .chart-icon {
    max-width: 98px;
  }
}

.section3-container {
  display: flex;
  padding: 20px;
  margin: 20px;
}

.data-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  cursor: pointer;
}

.label-container {
  font-family: AvenirHeavy, serif;
  font-size: 35px;
  font-weight: 600;
  text-align: right;
  padding: 16px 0;
  color: var(--section3-unselected);
  line-height: 35px;
}

.selected-data {
  padding: 20px 0 20px 20px;
}

.selected-data-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selected-circle {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  color: #006dff;

  border: 3px solid;
  border-radius: 50%;
}

.selected-header {
  font-family: AvenirHeavy, serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  /* color: #5a5858; */
  color: var(--body_color);
}

.help-text {
  /* color: #5a5858; */
  color: var(--body_color);
  font-family: AvenirRegular, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 30px;
  padding-top: 15px;
}

.data-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 50px;
}

.selected {
  /* color: #5f5f5f; */
  color: var(--body_color);
}

.label-container:hover {
  /* color: #5f5f5f; */
  color: var(--body_color);
}

.actionLink {
  font-family: AvenirRegular, serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  color: #11be6a;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton {
  min-width: 260px;
  font-size: 20px;
  font-weight: 400;
  font-family: AvenirRegular, serif;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #11be6a;
  border-radius: 10px;
  color: #5a5858;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 767px) {
  .section3-container {
    flex-direction: column;
  }

  .section3-container > div {
    width: 100%;
  }

  .selected-data,
  .data-right-container {
    padding-left: 0;
  }

  .label-container {
    text-align: left;
  }
}

.section4-container {
    display: flex;
    flex-direction: column;
    /* padding: 20px 70px; */
    padding: 20px 0;
}

.section4-container1 {
    padding: 20px 150px;

}

.section4_banner {
    background-image: url(/static/media/aibanner_new.e267a894.png);
    background-repeat: no-repeat;
    background-size: contain;
    width:100%;
     height:400px;
    background-position: center 100%; 
    position: relative;
    align-self: center;
    text-align: center;
    scale:1;
    
}

/* .section4_banner>img {
    margin-left: -28px;
    width: calc(100% + 28px);
} */

.d-flex {
    display: flex;
}

.item-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--body_color);
}

.icon-class {
    height: 50px;
    object-fit: contain;
}

.item-heading {
    font-family: AvenirHeavy, serif;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 6px;
    color: var(--body_color);
}

.item-info {
    font-family: AvenirRegular, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 290px;
    margin-top: 0;

}

.item-link {
    font-family: AvenirRegular, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #006DFF;
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 50px;
}

.item-link:hover {
    color: #00387E
}

.section4-container-heading {

    font-family: AvenirRegular, serif;
    font-size: 60px;
    font-weight: 500;
    line-height: 73px;
    letter-spacing: 0em;
    padding: 50px 0 20px;
    /* margin-left: 50px; 
    margin-bottom: 25px;*/
    text-align: left;
    color: var(--body_color);
}


.stay_in_touch>.item-container {
    align-items: center;
}

.stay_in_touch>.item-container .item-info {
    text-align: center;
}

/* .section-helper-text {
    text-align: center;
} */


@media (max-width: 767px) {
    .last-container .section-heading {
        font-size: 30px;
    }

    .last-container .section-helper-text {
        font-size: 16px;
    }

    .section-heading {
        font-size: 18px;
    }

    .stay_in_touch>.item-container .item-info,
    .section4-container .item-info,
    .help-text {
        font-size: 15px;
        margin-top: 8px;
    }

    .stay_in_touch .item-heading,
    .section4-container .item-heading {
        font-size: 30px;
    }

    .section4-container>div>div:nth-child(2) {
        margin: 40px 0;
    }

    .section4-container-heading {
        text-align: center;
        font-size: 40px;
        line-height: 45px;
    }


}

@media (max-width: 420px) {


    .section-heading {
        font-size: 18px;
    }
}
.aibanner {
    background-image: url(/static/media/aibanner_new.e267a894.png);
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    height: calc(100vw * 0.26);
    background-position: center 100%; 
    position: relative;
    align-self: center;
    text-align: center;
    scale:1;
    margin-top: -20px;
}


.meeting_bannerr {

    width:100%;
    background-image: url(/static/media/aibanner_new.e267a894.png);
    background-repeat: no-repeat;
    position: relative;
    background-size:cover;
    padding-bottom:10%;
    padding-top:5%;
    height:400px;
    align-self: center;
    text-align: center;
    
}
.last-container {
  width: 100%;
  min-height: 700px;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-repeat: no-repeat; */
  /* background-size: 100vw 700px; */
  flex-direction: column;
  position: relative;
  overflow: hidden;
  scale: 1;
  right: 0;
  overflow-x: hidden;
}

/* .section-helper-text {
    font-family: AvenirMedium, serif;
    font-size: 20px;
    color: white;
    padding: 10px 20px 25px;
} */

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 20px;
  /* margin-left: 5px; */
  width: 373.5px;
}

.getStarted:hover {
  /* box-shadow: #ffffff85 0 5px 7px 0; */
  background-color: #0ea55a;
  /* transform: scale(1.05); */
}

.getStarted {
  width: 316px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #11be6a;
  padding: 15px;
}

.currencies {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.currencies-content {
  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 30s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.graphic-image {
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.button-1 {
    background-color:  #006dff;
    color: white;
    width: 185px;
    height: 50px;
    border-radius: 0;
}

.button-1:hover {
  background-color:  #00387e;
}

.button-2 {
  background-color: #11be6a;
  color: white;
  width: 185px;
  height: 50px;
  border-radius: 0;
}

.button-2:hover {
  background-color:  #0ea55a;
  /* border-bottom-color: #0ea55a; */

}


@media (max-width: 767px) {

  .actions{
    width: 340px;
  }

  .button-1, .button-2{
    width: 167px;
  }

  .last-container {
    min-height: 770px;
  }
}
.site_footer {
    background-color: var(--body_background);
    color: var(--body_color);
}

.site_footer .col {
    justify-content: flex-start;
}

.site_footer_inner {
    padding: 0px 0 0 32px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    max-width: 80%;
}

.site_footer_inner>div {
    flex: 1 1;
}

.social-wrapper ul {
    padding-left: 0;
}

.social-wrapper ul li {
    display: inline;
    margin: 0 20px 0 0;
}

.footer_center_text {
    text-align: center;
    font-size: 10px;
    padding: 20px 0 0;
}

.footer-text {
    font-size: 10px;
}

.copyright_bar {
    background-color: var(--body_background);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    max-width: 80%;
}

.row.copyright_bar {
    width: calc(100% + 1.5rem);
}

.flip_icon_container {
    position: relative;
}


.icon_container {
    position: relative;
    display: flex;
    width: 80%;
    grid-gap: 20px;
    gap: 20px;
}

.font_30x{
    font-size: 30px;
}

.flip_person_icon {
    top: -257px;
    position: absolute;
    /* right: 111px; */
    max-width: 270px;
    left: 2px;
}

.flip_woman {
    /* top: -279px; */

    top: -212px;
    max-width: 400px;
}

.copyright_text {
    font-size: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 50px;
}

.fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-inline: 25px;
    font-size: 10px;
}

.border-right{
    border-right: 1px solid var(--border-color);
}
.fit-content a{
    text-decoration: none;
    color: var(--body_color);
}

.social-connect-icons{
    transition: transform 330ms ease-in-out;
    width: 29px;
    height: 29px;
}

.social-connect-icons:hover{
   
    transform: scale(1.2);
    transition: transform 330ms ease-in-out;
}

.logo_ind {
    width: 60px;
    transition: transform 330ms ease-in-out;
}

.logo_ind:hover {
    transform: scale(1.1);
    transition: transform 330ms ease-in-out;
}

.link_sty:hover{
    color: var(--dark_text);
}

.need_help {
    position: absolute;
    top: -176px;
    right: 32px;
    color: #3ac04c;
    font-size: 23px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 10px 14px 22px;
    cursor: pointer;
  
}
.need_help_phone {
    position: absolute;
    top: -90px;
    right: 32px;
    color: #3ac04c;
    font-size: 23px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 10px 14px 22px;
    cursor: pointer;
  
}


.need_help:hover {
    color: #0c7e32;
}
.need_help_phone:hover {
    color: #098d35;
}

@media (max-width: 1347px) { 

   

    .flip_person_icon {
        top: -159px;
        position: absolute;
        right: -10px;
        max-width: 340px;
        
    }
    


}

@media (max-width: 1200px) { 


    .hide-img {
        display: none;
    }


}

@media (max-width: 767px) {

    .site_footer_inner {
        flex-direction: column;
        padding-left: 0;
        max-width: 100% ;
        border: none;

    }

    .site_footer>div>div {
        display: block;
        text-align: center;
        margin-top: 50;
    }

    .site_footer>div>div.flip_icon_container {
        width: 200px;
        height: 200px;

    }
    
    .mobile-row .btn{
        box-shadow: none !important;
        border: none !important;
        color: var(--body_color);
    }
    
    .footer-drop{
        display: flex;
        justify-content: space-between;
        width: 100%;
        transition: none !important;
    }
    
    .mobile-row{
        border-bottom: 1px solid var(--border-color) !important;
    }
    
    .border-top{
        border-top: 1px solid var(--border-color) !important;
    }

    .copyright_bar {
        max-width: 100%;
    }

    .copyright_text {
        padding-right: 0px;
    }

    .logo_ind {
        width: 110px;
    }

    .desktop-display {
        display: none;
    }
   
    .flip_person_icon_mobile {
        position: inherit;
        width: 230px;
   
        top:-60px;
        scale:1.2
    
    }

    .flip_person_icon_mobile.flip_woman {

        top: 26px;
    }

    .footercentre2 p,
    .copyright_text {
        font-size: 10px;
    }
}

@media (min-width: 767px) { 
    .mobile-display {
        display: none;
    }
}
.small-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.airdrop_banner {
  background-image: url("/static/media/Airdrop Banner 02 (1).e5a56274.png");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}

.ch-airdrop_banner {
  background-image: url(/static/media/first.caee0272.svg);
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}

.sb-airdrop_banner {
  background-image: url(/static/media/second.36a5fbbb.svg);
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.04;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}
.bitcoinnew_banner {
  background-image: url(/static/media/bitcoin.97f0ad10.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 635px;

  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
  margin-top: -110px;
}

.ferrari_banner {
  background-image: url(/static/media/ferrari.8d1041b5.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 0.99;
  height: 680px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}
.get_free_token {
  background-image: url(/static/media/get_free_tokens_bg.0847dd96.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 0.99;
  height: 730px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  /* padding-bottom: 80px; */
  /* margin-top: -210px; */
}
.crypto_carnival {
  background-image: url(/static/media/crypto_carnival.350235b6.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  /* padding-bottom: 80px; */
  /* margin-top: -210px; */
}

.giftcard_banner {
  background-image: url(/static/media/gftbanner.0a0ebde8.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}

.greeting_banner {
  background-image: url("/static/media/grt banner 1.6c0e3865.svg");
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  scale: 1.035;
  height: 1000px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: left;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 80px;
}
.yellow_bg_btn {
  background: #ffb300;
  color: #000;
  font-size: 16px;
  padding: 15px 65px;
}
.yellow_bg_btn:hover {
  color: #000;
  opacity: 0.9;
}
.wallstreet_banner {
  background-image: url(/static/media/new_wallstreet.225ad245.svg);
  margin-top: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  padding-bottom: 112px;
}

.hive_banner {
  background-image: url(/static/media/hive.10c335d0.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 628px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 60px;
  gap: 60px;
  padding-left: 312px;
  padding-bottom: 132px;
}

.crypto_banner {
  background-image: url(/static/media/white-1.f45c8dd1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  padding-bottom: 100px;
}

.bitcoin_banner {
  background-image: url(/static/media/white-2.31a98d94.svg);
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  padding-bottom: 172px;
}

.greet_banner {
  background-image: url(/static/media/black-1.03db852a.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;
  margin-bottom: 100px;

  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  grid-gap: 60px;
  gap: 60px;
  padding-bottom: 150px;
}

.xnft_banner {
  background-image: url(/static/media/black-2.236379af.svg);
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 503px;
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  padding-bottom: 172px;
}

.airdrop-link-1 {
  z-index: 100000;
  color: black;
  margin-left: -1087px;
  cursor: pointer;
}
.airdrop-link-2 {
  z-index: 100000;
  color: white;
  margin-left: 607px;
  cursor: pointer;
}

.airdrop-link-1:hover {
  color: #fff;
}
.airdrop-link-2:hover {
  color: #000;
}

.gift-link {
  color: black;
  margin-left: -8px;
}

.gift-link:hover {
  color: #616161;
}

.register-link {
  color: black;
  margin-left: -70px;
}
.register-links {
  color: black;
}
.register-linkss {
  color: black;
}
.register-linksss {
  color: white;
}

.register-link:hover {
  color: #616161;
}

.greeting-link {
  color: black;
  background-color: #ffb300;
  padding: 10px 20px;
  margin-left: -420px;

  position: relative;
  top: -180px; /* Adjust the value to move it upwards */
  z-index: 999; /* Set a higher value if needed */
}

.greeting-link:hover {
  color: black;
  background-color: #ffd000;
}

.wall-link {
  color: black;
}

.wall-link:hover {
  color: #616161;
}

.hive-link {
  color: #ffb300;
}

.hive-link:hover {
  color: #ff9d00;
}

.exch-link {
  color: #0ea55a;
}

.exch-link:hover {
  color: #11be6a;
}

.login-button-banner {
  border-bottom: 50px solid #00a150;
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}
.earnbutton {
  background-color: #00a150;
  border-radius: 0px;
  height: 35px;
  font-size: 13px;
}
.earnbutton:hover {
  background-color: #028342;
}

.login-button-banner:hover {
  border-bottom-color: #028342;
  border-left-color: transparent;
}

.launch-app-button-banner {
  border-top: 50px solid #00a150;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.launch-app-button-banner:hover {
  border-top: 50px solid #028342;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.trade_right {
  padding-bottom: 35px;
}

.line_height_1 {
  line-height: 1;
}

.mega_heading {
  font-size: 80px;
  font-family: AvenirMedium;
}

.flex-direction-row {
  flex-direction: row;
}

.text-green {
  color: #8be64b;
}

.tm_text {
  font-size: 15px;
  position: relative;
  bottom: 0;
  text-align: center;
}

.trade_percentage {
  font-family: AvenirHeavy;
  font-size: 130px;
  margin-top: 20px;
}

.earn_btn {
  font-size: 22px;
  min-height: 43px;
  margin: 35px auto 0;
}

.ant-btn.btn-success.earn_btn {
  max-width: 325px;
}
.bitcoin_banner_root {
  margin-top: 150px;

  z-index: 11;
}

.inexIcon {
  justify-content: flex-end;
}
@media (max-width: 767px) {
  html,
  body {
    overflow-x: hidden;
  }
  .bitcoin_banner_root {
    margin-top: 150px;
    flex-direction: column;
  }
  .bitcoinIcon {
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .inexIcon {
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    order: 0;
  }
  .inex_left {
    order: 2;
  }
  .small-container {
    flex-direction: column;
    text-align: center;
  }

  .airdrop-link {
    margin-left: 88px;
  }

  .register-links {
    font-size: 10px;
  }
  .register-linkss {
    font-size: 10px;
  }
  .bitcoinnew_banner {
    background-image: url(/static/media/bitcoin.97f0ad10.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 80px;
  }

  .ferrari_banner {
    background-image: url(/static/media/ferrari.8d1041b5.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 80px;
  }
  .get_free_token {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 80px;
  }
  .crypto_carnival {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 80px;
  }

  .yellow_bg_btn {
    background: #ffb300;
    color: #000;
    font-size: 14px;
    padding: 10px 45px;
  }
  .giftcard_banner {
    margin-top: 20px;
    width: 100%;
    height: 205px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 22px;
  }

  .airdrop_banner {
    margin-top: 20px;
    width: 100%;
    height: 205px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 22px;
  }

  .greeting-link {
    padding: 5px;
    font-size: 10px;
  }

  .greeting_banner {
    background-image: url("/static/media/grt banner 1.6c0e3865.svg");
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    scale: 1.035;
    height: 210px;
    background-position: center 100%;
    position: relative;
    align-self: center;
    text-align: left;
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 80px;
  }
  .wallstreet_banner {
    margin-top: 30px;
    width: 100%;
    height: 228px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 22px;
  }

  .hive_banner {
    width: 100%;
    height: 248px;
    background-position: center 100%;
    background-image: url(/static/media/hive-mobile.18c156f9.svg);
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;

    grid-gap: 30px;

    gap: 30px;
    padding-left: 0px;
    padding-bottom: 22px;
  }

  .crypto_banner {
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    margin-bottom: 20px;

    grid-gap: 30px;

    gap: 30px;
    padding-bottom: 47px;
  }

  .bitcoin_banner {
    margin-bottom: 20px;
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    grid-gap: 30px;
    gap: 30px;
    padding-bottom: 94px;
  }

  .greet_banner {
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;
    margin-bottom: 20px;
    justify-content: flex-end;

    grid-gap: 20px;

    gap: 20px;
    padding-bottom: 80px;
  }

  .xnft_banner {
    margin-bottom: 20px;
    width: 100%;
    height: 286px;
    background-position: center 100%;
    position: relative;

    grid-gap: 30px;

    gap: 30px;
    padding-bottom: 96px;
  }

  .ant-btn.btn-success.earn_btn {
    max-width: calc(100% - 30px);
  }

  .tm_text {
    font-size: 7px;
  }

  .mega_heading.text-white {
    font-size: 25px !important;
  }

  .mega_heading.trade_percentage .mega_heading.trade_percentage span {
    font-size: 30px !important;
  }

  .trade_right {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1400px) {
  .my-container {
    max-width: 1420px;
  }
}


.btn-danger {
  --bs-btn-bg: #11be6a;
  --bs-btn-hover-bg: #0ea55a;
  --bs-btn-active-bg: #11be6a;
  --bs-btn-border-color : grey;
  --bs-btn-hover-border-color : grey;
}

.scan-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
  color: var(--body_color);
}

.font_50x {
  font-size: 50px;
}

.scan-Image {
  object-fit: contain;
  width: 653px;
}

.scan-heading {
  color: white;
  font-family: AvenirRegular, serif;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

.helper-text {
  font-family: AvenirRegular, serif;
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 20px;
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.main-header {
  background-color: #000;
  padding: 26px 90px;
  height: 90px;
  padding-top: 0;
  padding-bottom: 0;
}

.undo_icon {
  transform: scaleX(-1);
  margin-right: 12px;
}

/* style={{ fontSize: "24px", color: "#5f5f5f" }} */
.swap_icons {
  font-size: 20px;
  color: #5f5f5f;
}

.setting__icon {
  width: 25px;
  margin-right: 12px;
}

/* 
.from__icon {
    margin-bottom: 30px;
} */

.swap__coin__circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 109, 255, 0.1);
  border: none;
  background-image: url(/static/media/downArrow.8abbcc91.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.swap__coin__circle:hover {
  /* background: rgba(0, 109, 255, 0.25); */
  background-color: #006dff;
  color: #fff !important;
  border: none;
  background-image: url(/static/media/swapArrow.afdb3405.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.info__text {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: flex-end;
}

.icon__label {
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  align-items: center;
}

.helper_text {
  color: #006dff;
  font-size: 14px;
  font-family: "AvenirHeavy";
}

.lady_swap_icon {
  padding-bottom: 120px;
  margin-top: -190px;
}

.question_iocn {
  color: #006dff;
  font-size: 20px;
}

.coming_soon {
  padding-top: 20px;
}

.coming_soon .page_name {
  text-transform: capitalize;
  /* padding-right: 10px; */
}

.ant-modal-close {
  width: auto;
}

@media (max-width: 767px) {
  .section-usp {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 50px;
  }

  .center-Image {
    height: 59px;
    margin-top: -58px;
  }

  .font-big {
    font-size: 18px;
  }

  .big_trade_font {
    font-size: 55px;
  }

  .big_trade_font sub {
    font-size: 10px !important;
  }

  .trade_to_earn_link {
    padding-bottom: 50px;
    display: block;
    padding-top: 100px;
  }

  .section-helper-text {
    font-size: 12px;
  }

  .cut_button {
    max-width: 150px;
  }

  .font_50x {
    font-size: 28px;
  }

  .logo {
    padding-left: 0.75rem !important;
  }

  .home-container {
    height: 800px;
  }

  .selected-header,
  .item-heading,
  .label-container {
    font-family: AvenirRegular, serif;
  }

  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }

  .chart_buy {
    display: none;
  }

  .buy_sell_bg {
    background-size: contain;
  }

  .accounts_container .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-left: 12px !important;
  }

  .accounts_container .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 20px !important;
  }

  .accounts_container .ant-tabs-tab {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  /* .big_trade_font {
        font-size: 28px;
}

.font-big {
    font-size: 28px;
}

*/
}

/* .chart_header {
    display:flex;
    align-items:center;
} */
.chart_header h1 {
  margin-bottom: 0;
  font-size: 30px;
  color: #5f5f5f;
  line-height: 1;
}

.chart_title {
  padding: 0 7px;
}

.arrow_container div {
  line-height: 0;
  padding-bottom: 2px;
}

.Chart_inner {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 19px 0;
  align-items: baseline;
}

/* .chart_inner_left_top {
    display:flex;
} */

.chart_inner_middle {
  color: #11be6a;
  font-size: 20px;
}

.chart_inner_right .ant-segmented {
  background-color: rgba(129, 129, 129, 0.1);
  font-size: 15px;
  color: #5f5f5f;
  padding: 0;
  border-radius: 5px;
}

.chart_inner_right .ant-segmented-item-label {
  width: 58px;
  font-size: 15px;
  min-height: 37px;
  line-height: 37px;
}

.chart_inner_right .chart_dynamic .ant-segmented-item-label {
  width: 80px;
}

.chart_inner_right .chart_dynamic label {
  padding-bottom: 0;
}

.chart_inner_right .ant-segmented-item-selected {
  background: #11be6a;
  color: #fff;
  border-radius: 5px;
}

.time {
  /* display:flex;
    justify-content: space-between; */
  padding: 10px 15px;
}

.time div {
  color: #5f5f5f;
  font-size: 20px;
}

.search_token {
    border: none;
    border-bottom: 1px solid rgba(0, 109, 255, 0.5);
    margin: 20px;
    width: calc(100% - 40px);
}

.search_token:hover,
.search_token:focus {
    border-bottom: 1px solid rgba(0, 109, 255, 1);
    box-shadow: none;

}

/* style={{ display: "flex", alignItems: "center", marginBottom: 30, padding: 0 }} */
.token {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: auto;
    padding-top: 15px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 109, 255, 0.25);
}

.token:hover {
    background: rgba(217, 217, 217, 0.4);
    border-bottom: 1px solid rgba(0, 109, 255, 0.25);
}

/* style={{ paddingLeft: 10, display: "flex", flexDirection: "column", alignItems: "baseline" }} */
.token__data {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

/* style={{ fontSize: "30px", color: "#5F5F5F", padding: "0", lineHeight: 1, display: "block" }} */
.token__data__title {
    font-size: 23px;
    color: #5F5F5F;
    line-height: 1;
    display: "block";
    text-transform: uppercase;
}

/* style={{ "color": "#5F5F5F", paddingLeft: 3 }} */
.token__data__subtitle {
    color: #5F5F5F;
    padding-left: 3px;
    font-size: 15px;
}

.common_token_wrapper {
    padding: 0 17px 20px;
}

.common_token_heading {
    padding-bottom: 20px;
}

.common__token {
    padding: 0 4px;
    border: none;
    border-right: 1px solid rgba(0, 109, 255, 0.2);
    height: auto;
}

.common__token:hover {
    border-right: 1px solid rgba(0, 109, 255, 0.2);
}

.common__token:hover .common__token__title {
    color: #333;
}

.common_tokens> :first-child {
    padding-left: 0;
}

.common_tokens> :last-child {
    padding-right: 0;
    border: 0;
}

.common__token__title {
    border: none;
    color: #5F5F5F;
    font-size: 23px;
    line-height: 1.2;
    padding-left: 4px;
}

.ant-btn.common__token[disabled] {
    opacity: 0.3;
}
h1 {
    margin-bottom: 0;
    font-size: 30px;
    color: #5f5f5f;
}

.card_title {

    font-size: 40px;
    color: #5f5f5f;
    line-height: 1.2;
}

.helper_text {
    color: #5f5f5f;
    font-size: 14px;
    font-family: AvenirHeavy;
}

.card_body {
    padding: 20px;

}

.swap__coin {
    padding: 23px 0 23px 0;
}

.footer {
    padding: 0 20px 20px 20px;
    color: #5f5f5f
}

.footer.ant-btn-primary:hover, .footer .ant-btn-primary:focus {
    background-color: #00387d;
    border-color: #00387d;
}

.footer_body {
    padding-top: 10px;
}


.disable_icon {
    opacity: 0.5;
}
.card.card-s {
    min-height:330px;
    padding:19px;
}
    




































































.card.bs_container,
.bs_main > .card {
  border: 1px solid var(--border-color);
}

.card.bs_container {
  min-height: 500px;
}

.padding-b-1x {
  padding-bottom: 10px;
}

.margin-t-1_5x {
  margin-top: 15px;
}

.bs_container {
  background: #ffffff;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 10px;
  max-width: 423px;
}

.bs_token_left {
  flex: 1 1;
}

.bs_curreny_left {
  flex: 1 1;
  text-align: center;
  font-size: 100px;
  padding: 6px 32px 20px 0;
  display: flex;
  flex: 1 1;
  align-items: baseline;
  justify-content: center;
}

.bs_curreny_left > input {
  font-size: 100px;
  height: 100px;
  text-align: center;
  border: 0;
  color: #5f5f5f;
}

.bs_container_main {
  padding: 44px 41px 44px 44px;
}

button {
  width: 100%;
  background: #11be6a;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  border: none;
  height: 55px;
  cursor: pointer;
}

.bs_container_header {
  margin: 0;
  font-size: 30px;
  padding: 26px;
  color: #5f5f5f;
  border-bottom: 1px solid rgba(246, 96, 54, 0.2);
}

.d-flex {
  display: flex;
}

.bs_footer_action {
  padding: 26px;
  border-top: 1px solid rgba(246, 96, 54, 0.2);
}

.bs_footer_action button {
  color: #ffffff;
  /* background: rgba(95, 95, 95, 0.2); */
  background-color: #11be6a;
  border-radius: 5px;
  height: 55px;
}

.bs_footer_action button:hover,
.bs_footer_action button:focus,
.bs_footer_action button:active {
  background-color: #0ea55a;
  outline: none;
}

.bs_footer_action button.disable_icon:hover,
.bs_footer_action button.disable_icon:focus,
.bs_footer_action button.disable_icon:active {
  background-color: #11be6a;
}

.bs_token_num {
  font-size: 25px;
  color: #5f5f5f;
}

.bs_token {
  border-top: 1px solid rgba(246, 96, 54, 0.2);
  padding: 20px;
}

.token_grey {
  color: rgba(18, 18, 18, 0.2);
  padding-left: 5px;
}

.justify-between {
  justify-content: space-between;
}

.bs_purchase {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #5f5f5f;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  margin: auto;
}

.bs_purchase .ant-space {
  padding: 8px 16px;
}

.bs_curreny {
  font-size: 100px;
  color: rgba(18, 18, 18, 0.5);
}

.bs_currency_symbol {
  font-size: 50px;
}

.bs_container_header.border-b-0 {
  border-bottom: 0;
}

input.search {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  height: 47px;
  font-size: 17px;
  padding: 14px 15px 15px 48px;
  color: #5f5f5f;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 40%;
}

.bs_container_select_main {
  padding: 20px;
}

.position-relative {
  position: relative;
}

input + .search_icon {
  position: absolute;
  left: 35px;
  top: 33px;
  border-right: 1px solid rgba(246, 96, 54, 0.2);
  padding-right: 8px;
}

input::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
}

.common_token_subTitle {
  color: rgb(95 95 95 / 20%);
  font-size: 25px;
  line-height: 1.2;
}

.bs_token_container .common_token_title {
  font-size: 25px;
  padding: 0 10px;
}

.bs_token_container {
  padding: 12px 24px;
}

.common_tokens > .bs_token_container:first-child {
  padding-left: 24px;
}

.common__token.bs_token_container {
  border-right: 0;
}

.bs_container_create_main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 285px;
  padding-top: 40px;
}

.bs_container_create_main div {
  font-size: 25px;
  color: #5f5f5f;
  padding: 25px 0;
}

.bs_container_create_footer {
  padding: 25px;
}

button.ant-btn-link.btn_link span {
  color: #11be6a;
  font-size: 20px;
  height: 39px;
  padding-top: 16px;
}

button.ant-btn-link.btn_link span:hover {
  color: #0ea55a;
}

.left_arrow {
  padding-right: 8px;
  cursor: pointer;
}

.bs_container.bs_form {
  padding: 32px;
}

/* .form_element input:not([type="checkbox"]) {
    background: #FFFFFF;
    border: 1px solid rgba(246, 96, 54, 0.2);
    border-radius: 3px;
    height: 51px;
    width: 100%;
    font-size: 15px;
    padding: 0 13px;
} */

.input_height {
  height: 50px;
}

.bs_main .input_icon {
  position: absolute;
  right: 21px;
  transform: translate(50%, 50%);
}

.bs_main input[type="text"],
.ant-input-password.ant-input-affix-wrapper {
  border-color: var(--border-color);
}

.terms_conditions {
  color: #2e2d2d;
  font-size: 15px;
  padding-left: 9px;
  text-align: center;
}

.text_link {
  color: #11be6a;
}

.text_link:hover {
  color: #0ea55a;
}

input[type="checkbox"] {
  height: 38px;
  width: 28px;
  background: #ffffff;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 3px;
}

.padding-tb-2x {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-tb-3x {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-4x {
  padding-top: 40px;
  padding-bottom: 40px;
}

.terms_conditions_container {
  padding-top: 10px;
  padding-bottom: 30px;
}

.justify-center {
  justify-content: center;
}

.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.verfication_text {
  width: 280px;
  font-size: 15px;
  color: #5f5f5f;
}

.otp_container input {
  width: 50px;
  height: 65.48px;
  background: #ffffff;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  font-size: 30px;
  text-align: center;
}

.send_code button {
  /* background: rgba(95, 95, 95, 0.2); */
  border-radius: 5px;
}

.bs_main label {
  font-size: 15px;
  color: #5f5f5f;
}

.phone_container .ant-input-group-lg .ant-select-single .ant-select-selector,
.phone_container input {
  background: #ffffff;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  height: 47px;
}

.border-0 .ant-select-selector {
  border: 0 !important;
}

.border-0.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 19px;
  /* color: rgba(18, 18, 18, 0.2); */
  color: #5f5f5f;
}

.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 47px;
}

.phone_container_right {
  flex: 1 1;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.ant-dropdown-menu-item {
  padding: 10px 20px;
  font-size: 15px;
}

.ant-dropdown-menu-item:hover {
  color: #11be6a;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
  color: #11be6a53;
}

.scan-container.bs_main,
.bs_wallet {
  padding-top: 120px;
}

.default-link {
  font-size: 15px;
  line-height: 20px;
  color: #5f5f5f;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.default-link:hover {
  color: #5f5f5f;
}

.border-default {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 3px;
  padding: 6px 15px;
}

.border-b-1x {
  border-bottom: 1px solid rgba(246, 96, 54, 0.2);
}

.margin-b-2x {
  margin-bottom: 20px;
}

.card_s.card {
  min-height: 294px;
}

.card_s .verfication_text {
  font-size: 17px;
}

.sms_verfication {
  position: relative;
  right: -104%;
}

.border_separator {
  border: 1px solid rgba(246, 96, 54, 0.2);
  padding: 20px;
  width: 100%;
  height: 2px;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  color: #11be6a;
  text-decoration: underline;
}

.text-heavy {
  font-family: AvenirHeavy;
}

.ant-input-affix-wrapper {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  height: 51px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0;
}

button.ant-btn-primary [disabled] {
  background-color: #5f5f5f;
  height: 55px;
  border-color: "#5F5F5F";
  color: "#fff";
  font-size: 20px;
  border-radius: 5px;
}

.bs_main .ant-btn-primary,
.bs_main .ant-btn-primary:hover,
.bs_main .ant-btn-primary:active,
.bs_main .ant-btn-primary:focus {
  height: 55px;
  border-color: #11be6a;
  background-color: #11be6a;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
}

.bs_main .ant-btn-primary[disabled],
.bs_main .ant-btn-primary[disabled]:hover {
  background: rgba(95, 95, 95, 0.2);
  border-color: rgba(95, 95, 95, 0.2);
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 0 19px;
  padding: 20px 10px;
  font-size: 30px;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab:hover {
  color: #11be6a;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #5f5f5f;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background-color: #11be6a;
}

.bs_main > .card {
  min-height: auto;
  padding-bottom: 20px;
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: #11be6a;
  border-color: #11be6a;
}

.ant-btn-dangerous.ant-btn-primary:hover {
  background-color: #0ea55a;
  border-color: #0ea55a;
}

.bs_link,
a.bs_link:hover {
  color: #11be6a !important;
}

.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.swap_Arrow_icon {
  position: absolute;
  right: 4px;
  top: 39%;
  opacity: 0.7;
}

.swap_Arrow_icon:hover {
  opacity: 1;
}

.bs_main .ant-btn-primary:hover {
  background-color: #0ea55a;
}

.bs_main .card__header {
  border-color: rgba(246, 96, 54, 0.2);
  min-height: 87px;
}

.bs_main .ant-btn-primary:hover,
.bs_main .ant-btn-primary:focus,
.bs_main .footer.ant-btn-primary:hover,
.bs_main .footer .ant-btn-primary:focus {
  border-color: rgba(246, 96, 54, 0.2);
  background-color: #0ea55a;
}

.bs_curreny_left input:hover,
.bs_curreny_left input:focus,
.bs_curreny_left input:active {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border: none;
  outline: none;
}

.bs_curreny_left > input.input_currency {
  max-width: 275px;
  font-size: 60px;
  font-size: 1.1ch;
}

.error_message {
  background: rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  font-size: 13px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.top_heading {
  font-size: 48px;
  font-family: AvenirLight;
}

.buy_sell_bg {
  background-image: url(/static/media/ladyBuyGoldImage.0aa80612.png);
  height: 650px;
  background-repeat: no-repeat;
  background-position: top center;
}

.responsive_container .card,
.responsive_container .bs_container {
  margin: 19px;
}

.index_link_info {
  background-color: #e4e4e4;
}

@media (max-width: 767px) {
  .responsive_container .card,
  .responsive_container .bs_container,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    min-width: 100%;
    max-width: 100%;
  }

  .bs_wallet .card,
  .bs_wallet .border-b-1x {
    min-width: unset;
    border-color: #d7d7d7;
  }

  .bs_wallet .ant-input-prefix,
  .bs_wallet .orange_input.ant-input-affix-wrapper,
  .bs_wallet .orange_input.ant-input-affix-wrapper:hover,
  .bs_wallet .orange_input input,
  .orange_input input:hover {
    border-color: #d7d7d7 !important;
  }

  .bs_wallet .padding-2x {
    padding: 0;
  }

  .bs_main label,
  .placeholder_info {
    color: #5f5f5f !important;
  }

  .responsive_container .card,
  .responsive_container.card,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    border: none;
  }

  .responsive_container .bs_container.bs_form {
    padding: 0 16px;
  }

  .responsive_container .top_heading {
    font-size: 35px;
  }

  .responsive_container h1 {
    font-size: 25px;
  }

  .responsive_container .secure-steps .font_20x {
    font-size: 13px;
  }

  .responsive_container .secure-steps .d-flex img {
    width: 40px;
    height: 40px;
  }

  .two-factor-text {
    font-size: 15px;
  }

  .verfication_text {
    font-size: 11px;
  }

  .verfication_text.email_verification {
    font-size: 13px;
  }

  .scan-container.bs_main {
    padding-top: 80px;
  }

  .bs_container.card {
    padding: 0 1rem;
  }

  .card.bs_container,
  .bs_main > .card {
    border: none;
  }

  .bs_curreny_left .font_60x {
    font-size: 60px;
  }
}

.bs_container.medium_card {
    min-width: 700px;
}

.medium_card .light_button, .light_button, .light_button:hover, .light_button:focus, .light_button:active {
    padding: 6px 15px 10px 40px !important;
    background-repeat: no-repeat;
    background-position: left 10px center;
}

.deposit_btn,
.deposit_btn:hover,
.deposit_btn:focus,
.deposit_btn:active {
    background-image: url(/static/media/depositIcon.63f93c64.svg);
}


.withdraw_btn,
.withdraw_btn:hover,
.withdraw_btn:focus,
.withdraw_btn:active {
    background-image: url(/static/media/rocketIcon.8b2673ad.svg);

}

.sell_screens .bs_token {
    padding: 12px 20px;
}

/* 
.sell_screens .bs_footer_action button {
    background-color: #00A151;
    border-color: #00A151;
} */
/* 
.sell_screens .bs_footer_action button:hover,
.sell_screens .bs_footer_action button:focus,
.sell_screens .bs_footer_action button:active {
    background-color: #026333 !important;
} */
/* 
.sell_screens .bs_footer_action button.disable_icon:hover,
.sell_screens .bs_footer_action button.disable_icon:focus,
.sell_screens .bs_footer_action button.disable_icon:active {
    background-color: #00A151 !important;
}

.sell_screens button.sell_btn {
    background-color: #00A151;
    border-color: #00A151;
} */

.font_w_800 {
    font-weight: 800;
}

.margin-t-3x {
    margin-top: 30px;
}


.margin-t-2x {
    margin-top: 20px
}

.margin-t-auto {
    margin-top: auto;
}

.buy_purchase_modal .ant-modal-title {
    font-size: 0;
    /* background-image: url("../../assets/arts/indexGreyLogoIcon.svg"); */

    background-image: url(/static/media/indexBlueLogoIcon.3671e474.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: auto;
}
.bs_container.medium_card {
  min-width: 700px;
}

.medium_card .light_button {
  padding: 6px 15px 10px 40px;
  background-repeat: no-repeat;
  background-position: left 10px center;
}

.bs_wallet_top {
  background: #11be6a;

  max-width: calc(100% - 160px);
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 5px;
}

.margin-l-0_5x {
  margin-left: 5px;
}

.margin-r-0_5x {
  margin-right: 5px;
}

.color_white {
  color: #fff;
}

.large_card {
  margin: 0 auto;
  max-width: calc(100% - 160px);
  margin-bottom: 300px;
  padding-top: 0;
}

.full_width_tabs {
  width: calc(100% - 160px);
}

.custom_table tr > td.ant-table-cell:nth-child(3) {
  text-align: right;
}

.orange_input.ant-input-affix-wrapper,
.orange_input.ant-input-affix-wrapper:hover,
.orange_input input,
.orange_input input:hover {
  border-color: rgba(246, 96, 54, 0.3);
}

.orange_input.ant-input-affix-wrapper {
  height: 30px;
}

.bs_wallet .ant-input-prefix {
  border-color: rgba(246, 96, 54, 0.3);
}

.bs_wallet .ant-tabs-extra-content {
  margin-right: 20px;
  margin-bottom: 10px;
}

.bs_wallet .ant-tabs-nav-wrap {
  padding-left: 20px;
}

.bs_wallet_top_banner {
  background-position-y: bottom 0;
  padding-bottom: 320px;
  background-image: url(/static/media/graphWave.3f1745c0.svg);
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(100% - 50px);
}

.bs_wallet_buttons {
  position: absolute;
  right: 0;
  top: 20px;
}

@media (max-width: 767px) {
  .bs_wallet_top {
    max-width: 90%;
    display: block !important;
  }
  .bs_wallet_top div:nth-child(2) a {
    margin-left: 0;
  }
  .bs_wallet_buttons {
    position: inherit;
  }
  .bs_wallet_table table th,
  .bs_wallet_table table td {
    padding: 16px 8px;
  }
  h1 {
    font-size: 25px;
  }
}

.LineGraph_container__3aWV2 {
  margin: auto;
  max-width: 1200px;
}

.LineGraph_graph__10x0G {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.LineGraph_graph__10x0G h3 {
  text-align: center;
}

.LineGraph_graphBackground__zeJH7 {
  background-color: white;
  width: auto;
  height: auto;
}

.LineGraph_xscroll__1jCyk {
  overflow: scroll;
}

.LineGraph_button__SKbwo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #112d3f;
  color: white;
  width: 65px;
  height: 23px;
  margin-right: 3rem;
  cursor: pointer;
  border: none;
  margin: 0.4rem;
  border-radius: 5px;
}

.LineGraph_button__SKbwo:hover {
  background-color: #236086;
}

.LineGraph_Chart_inner__1AYyh {
  padding: 10px 0;
}
.Graph_error__2jGJ1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10rem;
    padding-right: 6rem;
  }
  h5 {
    width: 470px;
    border: 1px red solid;
    background-color: rgb(214, 77, 77);
    text-align: center;
    font-size: 1rem;
  }
  
  @media only screen and (max-width: 500px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 7rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20rem;
      padding-right: 0rem;
    }
    h5 {
      width: 370px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  
/* #root {
    display: flex;
    align-items: center;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  } */

.stripeForm {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: auto;
}

.ant-modal-close {
  width: auto;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripeButton {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripeButton:hover {
  filter: contrast(115%);
}

.stripeButton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 767px) {

  .stripeForm {
    min-width: 100%;
    padding: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
.nav_container {
    padding: 90px 90px 0 55px;
}

.help_content_page {
    padding: 53px 0 180px 60px;
    border-left: 1px solid rgba(0, 109, 255, 0.2);
    width: 100%;
}

.nav_main_header {
    padding-bottom: 7px;
}

h1 {
    margin: 0;
    font-size: 30px;
    color: #5f5f5f;
}

.nav_container p {
    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.info {
    padding: 15px 30px;
    background: rgba(211, 211, 211, 0.4);
    margin-top: 12px;
    font-size: 25px;
    color: #5f5f5f;
}

.nav_section {
    padding: 23px 0 27px 0;
}

.nav_section h1 {
    margin-bottom: 30px;
    margin-top: 20px;
}

.margin-t-0 {
    margin-top: 0;
}

.margin-b-0 {
    margin-bottom: 0;
}

.margin-l-3x {
    margin-left: 30px;
}

.margin-l-2x {
    margin-left: 20px;
}

/* Team.css*/
.team_members_ul {
    padding-top: 25px;
}

.team_members_ul li {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-bottom: 5px;
    padding-top: 5px;

    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}



/*Contact Us*/
.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}




/*Left Nav.css*/
.lef_nav_container {
    padding: 53px 0px 0 20px;
    min-width: 286px;
    height: auto;
}

.lef_nav_container ul, ul {
    padding: 0;
    list-style-type: none;
    margin-top: 0;
}

.lef_nav_container ul li a {
    font-size: 20px;
    color: #5f5f5f;
    margin-bottom: 8px;
    padding-left: 8px;
    display: inline-block;
    width: 100%;
    padding: 8px 0 8px 16px;
}


.lef_nav_container ul li a.active {
    background: rgba(211, 211, 211, 0.4);
    border: none;
}

.contat_us {
    padding-left: 5px;

}

.contat_us>a {
    color: #5F5F5F;
}

.contat_us>a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {

    .nav_container {
        padding: 90px 10px 40px 10px;
    }
    .ant-collapse {
        width:100%;
    }
    .help_content_page {
        padding: 0px 0 30px 0px;
        border-left: 0;
        width: 100%;
    }
    .ant-collapse-content > .ant-collapse-content-box {

        padding:0;
    }
    .info{
        margin-top:0;
    }

    .ant-collapse > .ant-collapse-item {
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
        flex: inherit;
        font-size: 25px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        justify-content: center;
        flex-direction: row-reverse;
    line-height:25px;
}

.ant-collapse {
    border:0;
}
}
.firstButton {
  background-color: #00b64f !important;
  border-radius: 10;
  border-color: #00b64f !important;
  width: 200px;
  height: 30px;
}

.firstButton:hover {
  background-color: #007433 !important;
  border-color: #007433 !important;
}

.secondButton {
  background-color: #0079fa !important;
  border-radius: 10;
  border-color: #0079fa !important;
  width: 200px;
  text-align: center;
  height: 30px;
}

.secondButton:hover {
  background-color: #012dac !important;
  border-color: #012dac !important;
}

.thirdButton {
  background-color: #1d860a !important;
  border-radius: 10;
  border-color: #1d860a !important;
  width: 200px;
  height: 30px;
}

.thirdButton:hover {
  background-color: #155e09 !important;
  border-color: #155e09 !important;
}

.fourthButton {
  background-color: #0259b8 !important;
  border-radius: 10;
  border-color: #0259b8 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.wibsButton {
  background-color: #fa8d01 !important;
  border-radius: 10;
  border-color: #fa8d01 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.wibsButton:hover {
  background-color: #d47902 !important;
  border-color: #d47902 !important;
  text-align: center;
}
.fourthButton:hover {
  background-color: #03468d !important;
  border-color: #0259b8 !important;
  text-align: center;
}

.fifthButton {
  background-color: #ffc205 !important;
  border-radius: 10;
  border-color: #ffc205 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}

.fifthButton:hover {
  background-color: #e0aa05 !important;
  border-color: #e0aa05 !important;
  text-align: center;
}

.sixthButton {
  background-color: #000000 !important;
  border-radius: 10;
  border-color: #ffffff !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color: white;
}

.sixthButton:hover {
  background-color: #353434 !important;
  border-color: #ffffff !important;
  text-align: center;
  color: white;
}

/* .sixthButton {
  background-color: #ffffff !important;
  border-radius: 10;
  border-color: #020202 !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color:#000000;
}

.sixthButton:hover {
  background-color: #ffffff !important;
  border-radius: 10;
  border-color: #020202 !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color:#000000;
} */

.photext {
  font-size: 35px;
  color: #ffc205;
}

.pholinktext {
  font-size: 14px;
  color: #ffc205;
}
.pholinktext:hover {
  color: #e0aa05;
}

.photext:hover {
  font-size: 35px;
  color: #e0aa05;
}

.cryptotext {
  font-size: 35px;
  color: #00b64f;
}

.cryptolinktext {
  font-size: 14px;
  color: #00b64f;
}
.cryptolinktext:hover {
  color: #029743;
}

.cryptotext:hover {
  font-size: 35px;
  color: #029743;
}

.in500text {
  font-size: 35px;
  color: #0079fa;
}
.in500text:hover {
  font-size: 35px;
  color: #012dac;
}
.in500linktext {
  font-size: 14px;
  color: #0079fa;
}
.in500linktext:hover {
  color: #012dac;
}

.iusdtext {
  font-size: 35px;
  color: #1d860a;
}
.iusdtext:hover {
  font-size: 35px;
  color: #155e09;
}
.iusdlinktext {
  font-size: 14px;
  color: #1d860a;
}
.iusdlinktext:hover {
  color: #155e09;
}

.inextext {
  font-size: 35px;
  color: #0259b8;
}
.inextext:hover {
  font-size: 35px;
  color: #03478f;
}
.inexlinktext {
  font-size: 14px;
  color: #0259b8;
}
.wibslinktext {
  font-size: 14px;
  color: #f58d06;
}
.wibslinktext:hover {
  font-size: 14px;
  color: #cc7503;
}
.inexlinktext:hover {
  color: #03478f;
}

/* .sorekttext {
  font-size: 35px; */
/* color: #000000; */
/* color: var(--body_color) !important; */

/* } */

/* .sorektlinktext {
  font-size: 14px;
  color: var(--body_color) !important; */
/* color: #000000; */
/* } */

.scaleup:hover {
  scale: 1.4;
}

.in500text {
  font-size: 35px;
  color: #0079fa;
}
.in500text:hover {
  font-size: 35px;
  color: #012dac;
}
.in500linktext {
  font-size: 14px;
  color: #0079fa;
}
.in500linktext:hover {
  color: #012dac;
}

.iusdtext {
  font-size: 35px;
  color: #1d860a;
}
.iusdtext:hover {
  font-size: 35px;
  color: #155e09;
}
.iusdlinktext {
  font-size: 14px;
  color: #1d860a;
}
.iusdlinktext:hover {
  color: #155e09;
}

.show-m {
  color: #1d860a;
  font-size: 13px;
  background: none;
}
.show-m:hover {
  background: none;
  color: #155e09;
}

.inextext {
  font-size: 35px;
  color: #0259b8;
}
.inextext:hover {
  font-size: 35px;
  color: #03478f;
}
.inexlinktext {
  font-size: 14px;
  color: #0259b8;
}
.inexlinktext:hover {
  color: #03478f;
}

.sorekttext {
  font-size: 35px;
  /* color:#353434; */
  color: var(--body_color) !important;
}
.sorekttext:hover {
  font-size: 35px;
  /* color:#212121; */
  color: var(--table_header) !important;
}
.sorektlinktext {
  font-size: 14px;
  /* color:#353434 !important; */
  color: var(--body_color) !important;
}
.sorektlinktext:hover {
  /* color:#353434 !important; */
  color: var(--table_header) !important;
}

.scaleup:hover {
  scale: 1.2;
}
@media screen and (max-width: 768px) {
  .how-it-works {
    grid-gap: 100px;
    gap: 100px;
  }

  .how-it-works .row {
    flex-direction: column;
    grid-gap: 100px;
    gap: 100px;
    margin-top: 0 !important;
  }

  .how-it-works .col-3 {
    width: 100%;
  }
}

.nav_container {
    padding: 90px 90px 0 55px;
}

.help_content_page {
    padding: 53px 0 180px 60px;
    border-left: 1px solid rgba(0, 109, 255, 0.2);
    width: 100%;
}

.nav_main_header {
    padding-bottom: 7px;
}

h1 {
    margin: 0;
    font-size: 30px;
    color: #5f5f5f;
}

.nav_container p {
    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.info {
    padding: 15px 30px;
    background: rgba(211, 211, 211, 0.4);
    margin-top: 12px;
    font-size: 25px;
    color: #5f5f5f;
}

.nav_section {
    padding: 23px 0 27px 0;
}

.nav_section h1 {
    margin-bottom: 30px;
    margin-top: 20px;
}

.margin-t-0 {
    margin-top: 0;
}

.margin-b-0 {
    margin-bottom: 0;
}

.margin-l-3x {
    margin-left: 30px;
}

.margin-l-2x {
    margin-left: 20px;
}

/* Team.css*/
.team_members_ul {
    padding-top: 25px;
}

.team_members_ul li {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-bottom: 5px;
    padding-top: 5px;

    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}



/*Contact Us*/
.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}




/*Left Nav.css*/
.lef_nav_container {
    padding: 53px 0px 0 20px;
    min-width: 286px;
    height: auto;
}

.lef_nav_container ul, ul {
    padding: 0;
    list-style-type: none;
    margin-top: 0;
}

.lef_nav_container ul li a {
    font-size: 20px;
    color: #5f5f5f;
    margin-bottom: 8px;
    padding-left: 8px;
    display: inline-block;
    width: 100%;
    padding: 8px 0 8px 16px;
}


.lef_nav_container ul li a.active {
    background: rgba(211, 211, 211, 0.4);
    border: none;
}

.contat_us {
    padding-left: 5px;

}

.contat_us>a {
    color: #5F5F5F;
}

.contat_us>a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {

    .nav_container {
        padding: 90px 10px 40px 10px;
    }
    .ant-collapse {
        width:100%;
    }
    .help_content_page {
        padding: 0px 0 30px 0px;
        border-left: 0;
        width: 100%;
    }
    .ant-collapse-content > .ant-collapse-content-box {

        padding:0;
    }
    .info{
        margin-top:0;
    }

    .ant-collapse > .ant-collapse-item {
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
        flex: inherit;
        font-size: 25px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        justify-content: center;
        flex-direction: row-reverse;
    line-height:25px;
}

.ant-collapse {
    border:0;
}
}
body {
    color: var(--body_color);

}

.blog_container {
    padding-top: 90px;
}

.font_60x {
    font-size: 60px;
    line-height: 1.2;
}

body{
    margin:0%;
    padding:0%;
    overflow-x:hidden;
}

@media (min-width: 992px) {
    .blog_Responsive .col-lg-6 {
        flex: 0 0 auto;
        width: calc(50% - 10px);
    }
}

.text-color{
    color: var(--body_color);
    text-decoration: none;
    max-width: 587px;
    min-height: 125px;
}
/* .blog_Responsive .col-lg-6 {
    flex: 0 0 auto;
    width: calc(50% - 10px);
} */

.font_48x {
    font-size: 48px;
    line-height: 1.2;
}

.font_30x {
    font-size: 30px;
    line-height: 1.2;
}

/* .blog_flipicon_image {
    flex-basis: calc(62% - 20px);
    background: #E6E6E6;


} */

/* .blog_flipicon_image img {
    position: absolute;
    transform: translatex(50%);
    bottom: 0px;
} */

/* .blog_flipicon_image_content {
    flex-basis: calc(38% - 20px);
    color: #5A5858;

} */

.flex-b-50 {
    flex-basis: calc(50% - 20px);
}

.border-1x {
    border: 1px solid rgba(0, 109, 255, 0.5);
}

.border-1x.orange {
    border: 1px solid rgba(246, 96, 54, 0.2);
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-b-3x {
    margin-bottom: 30px;
}

.margin-tb-2x {
    margin-top: 20px;
    margin-bottom: 20px;
}

.padding-0 {
    padding: 0 0;
}

@media (max-width: 767px) {

    .blog_container .font_23x, .blog_container .default-link.font_20x, .blog_container .font_20x {
        font-size: 15px !important;


    }

    .blog_container .font_40x {

        font-size: 25px !important;
        text-align: center;
    }

    .blog_container {
        /* padding-top: 90px; */
        margin: 0 19px 19px;
    }

    .blog_container .border-1x.orange, .blog_container .border-1x {

        border: 0 !important;
    }

    .font_60x {
        font-size: 35px;

    }
}


.chat-bot-container{
    border: 2px;
    border-color: gray;
    border-style:ridge;
    padding:30px;
    opacity:70%;
    
}

.resultDisplay{
    width:700px;
    height:400px;
    text-align: left;
}

.botimage{
    text-align: left;
    width:100px;
    align-items: left;
    align-self: left;
}
.ant-space-item{
    text-align: left;
}
.border {
  border: 1px solid rgba(0, 109, 255, 0.2);
}

.scan-container.market-container {
  flex-direction: column;
}

.search-input-container {
  width: 508px;
  height: 44px;
}

.ant-input-prefix {
  border-right: 1px solid green;
  padding-right: 7px;
}

.scan-container.scan-container-trade {
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}

.main-left {
  flex-grow: 1;
  max-width: calc(100% - 350px);
}

.trade_content {
  /* max-width: calc(100% - 640px); */
}

.trade_radio .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
  height: 41px;
  line-height: 41px;
  font-size: 20px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

.trade_tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.trade_tabs.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
  margin: 0 20px;
}

.ant-tabs-tab {
  font-size: 18px;
  color: rgba(95, 95, 95, 0.5);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5f5f5f;
}

.border-1x,
.card.main-right {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
}

.card.main-right {
  min-width: 350px;
  max-width: 350px;
}

.main-left .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.login_tabs {
  min-height: 400px;
  border: 1px solid rgba(246, 96, 54, 0.2);
}

.login_tabs .ant-tabs-content-holder {
  display: flex;
  align-items: center;
  text-align: center;
}

.main-left .ant-table-thead > tr > th,
.main-left .ant-table-tbody > tr > td,
.main-left .ant-table tfoot > tr > th,
.main-left .ant-table tfoot > tr > td {
  padding: 0;
}

.main-left .ant-table-thead > tr > th {
  background-color: #fff;
  border-bottom: 0;
}

.main-left
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

.trade_content .ant-table-thead > tr > th:nth-child(3),
.trade_content .ant-table-tbody > tr > td:last-child {
  text-align: right;
}

.trade_content .ant-table-thead > tr > th:nth-child(2),
.trade_content .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center;
}

.orange .ant-tabs-ink-bar {
  background: #11be6a;
}

.orange .ant-tabs-tab:hover {
  color: #11be6a;
}

.border,
.ant-input-affix-wrapper {
  border: 1px solid rgba(0, 109, 255, 0.2);
  border-radius: 5px;
}

.scan-container.market-container {
  flex-direction: column;
  color: var(--body_color);
}

.search-input-container {
  width: 508px;
  height: 44px;
}

.ant-input-prefix {
  border-right: 1px solid rgba(0, 109, 255, 0.2);
  padding-right: 7px;
}

.tabs-container {
  width: 95%;
  margin: 67px auto 40px;
  min-height: 40px;
}

.market-container .ant-tabs-top > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > .ant-tabs-nav::before,
.market-container .ant-tabs-top > div > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.market-container .ant-tabs-nav {
  padding-left: 60px;
}

.grey-strip {
  background: #d9d9d966;
  padding: 24px 21px 19px;
}

.white-strip {
  background: var(--market-table-color);
  color: var(--body_color);
  min-width: 100px;
  height: 32px;
  border-radius: 5px;
  width: auto;
}

.white-strip:hover {
  background: var(--market-table-color);
  /* color: var(--body_color); */
}

.margin-lr-2x {
  margin-left: 20px;
  margin-right: 20px;
}

.white-strip.last-item {
  width: auto;
  margin-left: auto;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}

.tab-body-container .ant-table-content {
  margin: 0 20px;
  /* background-color: var(--market-table-color) !important; */
}
/* 
.tab-body-container .ant-table {
  background-color: var(--market-table-color);
}

.ant-table-thead > tr > .ant-table-cell {
  background-color: var(--market-table-color);
} */

.market-container .ant-tabs-tab {
  color: rgba(95, 95, 95, 0.5);
}

.market-container .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab:hover .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--body_color);
  font-size: 30px;
}

.market-container .ant-tabs-ink-bar {
  border-color: #11be6a;
  background-color: #11be6a;
}

.market-container .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 45px;
}

.ant-btn.btn-success {
  background-color: #00a151;
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-success:hover {
  background-color: #018342;
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-warn {
  border-color: transparent;
  border-radius: 4px;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-pagination.ant-pagination {
  display: none;
}

.market-container .ant-spin-dot {
  width: 50px;
  height: 50px;
}

@media (max-width: 767px) {
  .market_button_strips button {
    min-width: 50px;
    max-width: 90px;
    font-size: 12px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .market_button_strips .margin-lr-2x {
    margin-left: 8px;
    margin-right: 0;
  }

  .market-container .tab-body-container .ant-table-content {
    margin: 0 0;
  }

  .market-container .ant-tabs-tab + .ant-tabs-tab,
  .market-container .ant-tabs-tab:hover .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
  }

  .market-container .ant-tabs-nav {
    padding-left: 0;
  }

  .market-container h1 {
    font-size: 25px;
    padding: 0 16px;
  }

  .market-container .search-input-container {
    width: 100%;
    padding: 0 20px;
  }

  .market-container .ant-tabs-tab:nth-child(2) {
    margin-left: 20px;
  }
}

.ant-pagination-item {
  color: var(--body_color);
  background-color: var(--body_background);
}

.ant-pagination-item-link, .ant-pagination-item-ellipsis {
    /* display: inline-block;
    min-width: 32px;
    height: 32px; */
    color: var(--body_color) !important;
  background-color: var(--body_background) !important;
}

/* .ant-select, .ant-pagination-options {
  color: var(--body_color) !important;
  background-color: var(--body_background) !important;
} */

.ant-select-selector{
  /* color: var(--body_color) ; */
  background-color: var(--body_background) !important;
}
.ant-select{
border: 1px solid #d9d9d9;
border-radius: 6px;
}
.ant-select-arrow, .ant-select-selection-item{
  color: var(--body_color) !important;

}
body {
    color: var(--body_color);

}

.blog_container {
    padding-top: 90px;
    
}
.blog_container:hover {
    cursor: pointer;
}

.font_60x {
    font-size: 60px;
    line-height: 1.2;
}
.gapleft{
    margin-left:5px;
}

@media (min-width: 992px) {
    .blog_Responsive .col-lg-6 {
        flex: 0 0 auto;
        width: calc(50% - 10px);
        
    }
}

/* .blog_Responsive .col-lg-6 {
    flex: 0 0 auto;
    width: calc(50% - 10px);
} */

.font_48x {
    font-size: 48px;
    line-height: 1.2;
}

.font_30x {
    font-size: 30px;
    line-height: 1.2;
}

.font_23x {
    font-size: 23px;
    color: var(--body_color) !important;
}

.font_20x {
    font-size: 20px;
    color: var(--body_color) !important;
}

/* .blog_flipicon_image {
    flex-basis: calc(62% - 20px);
    background: #E6E6E6;


} */

/* .blog_flipicon_image img {
    position: absolute;
    transform: translatex(50%);
    bottom: 0px;
} */

/* .blog_flipicon_image_content {
    flex-basis: calc(38% - 20px);
    color: #5A5858;

} */

.flex-b-50 {
    flex-basis: calc(50% - 20px);
}

.border-1x {
    /* border: 1px solid rgba(0, 109, 255, 0.5); */
    border: 1px solid var(--blog-border-orange) !important;

}

.border-1x.orange {
    border: 1px solid var(--blog-border-orange) !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-b-3x {
    margin-bottom: 30px;
}

.margin-tb-2x {
    margin-top: 20px;
    margin-bottom: 20px;
}

.padding-0 {
    padding: 0 0;
}

@media (max-width: 767px) {

    .blog_container .font_23x, .blog_container .default-link.font_20x, .blog_container .font_20x {
        font-size: 15px !important;


    }

    .blog_container .font_40x {

        font-size: 25px !important;
        text-align: center;
    }

    .blog_container {
        /* padding-top: 90px; */
        margin: 0 19px 19px;
    }

    .blog_container .border-1x.orange, .blog_container .border-1x {

        border: 0 !important;
    }

    .font_60x {
        font-size: 35px;

    }
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .loading-spinner {
    border: 1px solid;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(/static/media/loaderIcon.c8cece83.gif) center / 50px no-repeat;
  }
.logo_prod {
    transition: transform 330ms ease-in-out;
}

.logo_prod:hover {
    transform: scale(1.1);
    transition: transform 330ms ease-in-out;
}

.centerText {
    
    justify-content:center;
    align-items:center;
    text-align: center;
    
}

.card__title{
    color: var(--body_color);
}

.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.scan-container.wd_container {
  align-items: start;
  flex-direction: column;
}

.bs_main label,
.placeholder_info {
  font-size: 13px;
  color: rgba(95, 95, 95, 0.5);
}

.bs_main label {
  padding-bottom: 5px;
}

.select_container {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  padding: 8px 16px;
}

.font_23x {
  font-size: 23px;
  color: #5f5f5f;
}

.disabled_button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(95, 95, 95, 0.2);

  height: 42px;
  height: 55px;

  font-size: 20px;
  border-radius: 5px;
}

.w_fiat {
  width: 90%;
  margin: auto;
}

.w_fiat button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.font_13x {
  font-size: 13px;
}

.danger_disabled {
  color: rgba(95, 95, 95, 1);

  border-color: rgba(95, 95, 95, 0.2);
}

.W_form .ant-row {
  display: block;
}

.W_form .ant-col-16 {
  max-width: 100%;
}

.W_form input {
  height: 55px;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
  font-size: 15px;
}

.W_form input:hover {
  border: 1px solid rgba(246, 96, 54, 0.2);
}

.W_form {
  height: 350px;
  overflow-y: auto;
}

.W_form label,
.placeholder_info {
  color: #5f5f5f;
}

.border-t-1x {
  border-top: 1px solid rgba(246, 96, 54, 0.2);
}

.border-1x {
  border: 1px solid rgba(246, 96, 54, 0.2);
}

.max_400 {
  max-width: 400px;
}

.padding-1x {
  padding: 10px;
}

.brand_opacity_5 {
  color: rgba(95, 95, 95, 0.5);
}

.padding-tb-1x {
  padding-top: 10px;
  padding-bottom: 10px;
}

.flex_buttons button {
  width: 47%;
}

.ant-radio-inner::after {
  background-color: #11be6a;
}

.ant-radio-checked::after,
.ant-radio-checked .ant-radio-inner {
  border-color: #11be6a;
}

.ant-radio-wrapper,
.ant-radio-wrapper span {
  font-size: 15px;
}

.wd_container .helper_text {
  color: rgba(95, 95, 95, 0.5);
  font-weight: normal;
  font-family: AvenirRegular;
}

.wd_container .radio_text {
  font-family: AvenirHeavy;
  color: #5f5f5f;
  font-size: 16px;
}

.w_50 {
  width: calc(50% - 20px);
}

.font_weight_800 {
  font-weight: 800;
}

.font_40x {
  font-size: 40px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 55px;
  border-radius: 5px;
  border: 1px solid rgba(246, 96, 54, 0.2);
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 21%;
}

.bs_main label.ant-radio-wrapper {
  color: #5f5f5f;
}

.bg_icon {
  background: #11be6a;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  padding: 2px;
}

.W_form .ant-col-8 {
  max-width: 100%;
}

.brand_color {
  color: #5f5f5f;
}

.popover_container {
  width: 300px;
  min-height: 304px;
}

.disc_ul {
  list-style-type: disc;
  margin-left: 20px;
}

.ant-popover-inner-content {
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
}

.margin-l-1x {
  margin-left: 10px;
}

.ant-popover-inner {
  border-radius: 5px;
}

.popover_container_link,
.popover_container_link:hover {
  color: #5f5f5f;
  text-decoration: underline;
}

.amount_container input {
  border: none;
}

.ant-btn.disabled_button:hover,
.ant-btndisabled_button:focus {
  color: #11be6a;
  border-color: #11be6a;
}

.text_line {
  text-decoration: underline;
}

.ant-select-selection-placeholder {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
}

.border-r-1x {
  border-right: 1px solid #5e5e5e;
}

.padding-r-1x {
  padding-right: 10px;
}

.padding-l-1x {
  padding-left: 10px;
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: #11be6a;
  border: 1px solid rgba(246, 96, 54, 0.2);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 50px 0 0;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 20px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.large_card .reset_button {
  top: 41px;
  right: 0;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}

.bank_info_card {
    min-width: 550px;
}

.font_28x {
    font-size: 28px;
}

.no-outline, .outline-none {
    outline: none;
}

@media (max-width: 767px) {

    .deposit_ontainer {
        display: block !important;
    }

    .deposit_ontainer .order_history button {
        margin-left: 0;
    }

    .top_heading {
        font-size: 35px;
    }

    .amount_container {
        font-size: 22px;
    }

    .d_crypto_Container {
        display: block !important;
    }

    .d_crypto_Container .crypto_con_button {
        /* text-align: center; */
        text-align: end;
    }

    .d_crypto_status {
        justify-content: space-between;
    }

    .responsive_recent_deposits .d-flex,
    .responsive_recent_deposits.d-flex {
        display: block !important;
    }

    .responsive_recent_deposits .font_15x {
        font-size: 10px;
    }

    .wallet_funding {
        padding-left: 20px;
    }

    .recent_deposit_container {
        padding: 0;
        border: 0;
    }

    .w_fiat .font_40x {
        font-size: 15px;
        font-weight: bold;
    }

}
.about-container {
    height: 600px;
}

.handsImage {
    width: 50%;
    margin: auto;
    transform: translateY(-60%);
}

.row{
    width: 100%;
}
.disignation {
    font-size:12px;
}
.employee_name {
    font-size:15px;
}

.main_container {
    padding: 0 10%;
}

.main_container .col-lg-4 {
    margin-top:20px;
}

@media (max-width: 767px) {

    .about-container {
        height: 338px;
    }
    .font_40x {
        font-size: 25px;
    }
    .font_30x {
        font-size: 15px;
        line-height: 1.2;
    }
    

.handsImage {
    width: 63%;
}


}
.accounts_container .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #f6f6f6;
  padding-left: 55px;
}

.basic_info > div,
.security_container.border-1x {
  border: 1px solid #e4e4e4 !important;
  border-radius: 5px;
}

.basic_info .border-b-1x,
.security_container .border-b-1x,
.authenticator_container.border-1x,
.security_change.card,
.security_change .ant-input-password.ant-input-affix-wrapper {
  border-color: #e4e4e4 !important;
}

.ant-tabs {
  color: #5f5f5f;
}

.border-1x-orange {
  border: 1px solid #11be6a;
  border-radius: 5px;
}
.ant-switch-checked {
  background: #11be6a;
}

.basic_funtion > div {
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 24px;
  background-size: auto;
  font-size: 15px;
}

.apple_pay {
  background-image: url(/static/media/applePayA.cb822446.svg);
}
.staking {
  background-image: url(/static/media/stakingA.044b5d73.svg);
}
.wire_transfer {
  background-image: url(/static/media/wireA.a9185a24.svg);
}

.otc_trading {
  background-image: url(/static/media/otcA.16d6caf2.svg);
}
.api_trading {
  background-image: url(/static/media/apiA.b7ad4c94.svg);
}
.adv_trade {
  background-image: url(/static/media/tradeA.09ac83b8.svg);
}
.usd_deposit {
  background-image: url(/static/media/dollarA.90b5c699.svg);
}
.crypto_deposit {
  background-image: url(/static/media/airplane.411f031c.svg);
}

.buy_Sell_convert {
  background-image: url(/static/media/BSArrowA.c21099d0.svg);
}

.bank_trns_debit {
  background-image: url(/static/media/RectangleA.3bc8bb3a.svg);
}

.security_container .btn-primary {
  background: #11be6a;
  height: 38px;
  border-color: #11be6a;
}

.security_container .height_28x {
  height: 28px;
  font-size: 13px;
  border-radius: 5px;
}
.padding-l-24px {
  padding-left: 24px;
}

.border_radius_5x {
  border-radius: 5px;
}

.basic_funtion .disabled_button,
.basic_funtion .disabled_button:hover {
  background: #e4e4e4;
  color: #5f5f5f;
  border-color: #e4e4e4;
  font-size: 18px;
}

.security_form_element .ant-row {
  display: block;
}

.bs_main .security_form_element label,
.security_form_element .placeholder_info {
  font-size: 13px;
  color: #5f5f5f;
}

.ant-tabs-tab:hover {
  color: #5f5f5f;
}

.ant-tabs-ink-bar {
  background: #11be6a;
}
.font_17x {
  font-size: 17px;
}

.zombieButton {
    background-color:#9bc61b!important; 
    border-radius: 10;
    border-color: #9bc61b!important; 
    width: 280px;
    height: 43px;
}

.zombieButton:hover{
    background-color: #85a919!important;
    border-color: #85a919!important;

}


.secButton {
    background-color: var(--dark_text)!important; 
    border-radius: 10;
    border-color: var(--dark_text) !important; 
    width: 280px;
    text-align: center;
    height: 43px;
    color: var(--body_background);
}

.secButton:hover{
    background-color: var(--dark_text)!important; 
    border-color: var(--dark_text) !important; 
    color: var(--body_background);
}

.tButton {
    background-color:#a804ff!important; 
    border-radius: 10;
    border-color: #9205de!important; 
    width: 280px;
    height: 43px;
}

.tButton:hover{
    background-color: #7802b7!important;
    border-color: #7802b7!important;
}



.skButton {
    background-color:#fc9104!important; 
    border-radius: 10;
    border-color: #fc9104!important; 
    width: 280px;
    height: 43px;
}

.skButton:hover{
    background-color: #ea8706!important;
    border-color: #ea8706!important;
}








.skulltext{
    font-size: 35px;
    color:#9bc61b
}

.skulllinktext{
    font-size: 14px;
    color:#9bc61b
}
.skulllinktext:hover{
    color:#8fb718
}

.skulltext:hover{
    font-size: 35px;
    color:#83a716
}



.srtext{
    font-size: 35px;
    color:var(--dark_text);
}
.srtext:hover{
    font-size: 35px;
    color:var(--dark_text);
}
.srlinktext{
    font-size: 14px;
    color:#000000;
}
.srlinktext:hover{
    color:#000000;
}



.mftext{
    font-size: 35px;
    color:#a804ff;
}
.mftext:hover{
    font-size: 35px;
    color:#9003db;
}
.mflinktext{
    font-size: 14px;
    color:#a804ff;
}
.mflinktext:hover{
    color:#9003db;
}


.inextext{
    font-size: 35px;
    color:#0259B8;
}
.inextext:hover{
    font-size: 35px;
    color:#03478f;
}
.inexlinktext{
    font-size: 14px;
    color:#0259B8;
}
.inexlinktext:hover{
    color:#03478f;
}







.sktext{
    font-size: 35px;
    color:#fc9104;
}
.sktext:hover{
    font-size: 35px;
    color:#e78403;
}
.sorektlinktext{
    font-size: 14px;
    color:#fc9104;
}
.sktexttext:hover{
    color:#e78403;
}



.scaleup:hover{
    scale: 1.2;
}

.notification_container .border-b,
.help_content_page.notification_container{
    border-color:#E4E4E4;
}

@media (max-width: 767px) {
.nav_container.notification_nav_container {
    padding: 2px 10px 40px 10px;
} 

.nav_main_header>div:last-child{
    border:none;
}
}

.width_50 {
  width: 50%;
}

.apply_button,
.apply_button:hover,
.apply_button:active,
.apply_button:focus {
  background-color: #11be6a;
  color: #fff;
  font-size: 20px;
  height: 46px;
}

.margin-t_300x {
  margin-top: 300px;
}

.career_icon {
  background-image: url(/static/media/CareerIcon.15259b2c.svg);
}

.my_sty{
  color: var(--body_color);
}
.card__title{
    color: var(--body_color);
}

.cover-page .card-img-top{
    width: 35px;
    height: 32.32px;
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card-img-top:hover{
    transform: scale(1.3);
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 50px 10px 10px;
    width: 100px;
    height: 80px;
    border: none;
}

.cover-page .card-body{
    padding: 0;
}
.cover-page .col{
    width: 23%;
}

.cover-page  h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 10px !important;
    margin-top: 8px;
    color: var(--body_color);

}

.cover-page .card-text{
    justify-content: center;
    text-align: center;
    color: var(--body_color);
}



.main-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 0px;
    /* width: 318.87px;
    height: 250px; */
    border-radius: 2px;
    border: none;
    /* background-color: var(--card-color); */
}

/* .main-page .card-img-top{
    width: 318.87px;
    height: 200px;
} */

.main-page .col{
    width: 29%;
    display: flex;
    justify-content: center;
}

.up-logos .col{
    width: 10%;
}

.xnft-main .col{
    width: 26%;
}

.sub-main .col{
    width: 25%;
}

.usd-main .col{
    width: 47%;
}

.usd-main .card{
    border-radius: 0;
}

.usd-main .card-img-top{
    width: 588px;
    height: 248px;
}

.g-4, .gy-4 {
    --bs-gutter-y: 4rem;
}

.main-page h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 20px;
    margin-top: 5px;
}

.main-page .card-text{
    padding: 12px;
    justify-content: center;
    text-align: center;
}


.main-page .ant-btn-default{
    width: 318.87px;
    background-color: #0294fe !important;
    color: #fff;
    font-size: 15px;
    height: auto;
    padding: 10px;
    border-color: var(--border-color);
}

.main-page .ant-btn-default:hover{
    background-color: #0057ff !important;

}



.font_100x {
  font-size: 100px;
}
.font_150x {
  font-size: 150px;
}

.font_70x {
  font-size: 70px;
}

.font_60x {
  font-size: 60px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -25%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    grid-gap: 10px;
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-gap: 15px;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 12%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}
.font_42x{
  font-size: 42px;
}

.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
  }

  .img-contain{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-pile{
    padding-block: 50px;
  }
  /* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */
  
  .radio-button.selected {
    border-color: #007bff; /* Set the desired color for the outline */
  }

  .label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pay-image {
    /* width: 120px; */
    padding: 5px;
    height: 70px;
  }
  .select-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .select-image {
    padding: 5px;
    height:40px;
    text-align: left;
  }
  
  .pay-description {

    padding-top: 30px;
    font-size: 15px;
    text-align: center;
  }
  
  .selected-opt {
    border: 2px solid #0078E5; /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    height: 70px;
    /* width: 120px; */
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: var(--body_color) !important;
    border-radius: 2px !important;
    border: none;
    background-color: var(--body_background) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--border-color) !important;

  }

  .select-input{
    min-width: 330px !important;
  }

  .drop-image {
    width: 40px;
    margin-right: 10px;
  }

  .drop2-image {
    width: 35px;
    margin-right: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: var(--body_color) !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    background-color: var(--body_background) !important;
    color: var(--body_color) !important;
  }

  .hive-img{
    width: 105px;
    height: 75px;
  }

  .hive-img:hover{
    cursor: pointer;
    transform: scale(1.2);
  }

  .text-color{
    color:#333333
  }

@media (max-width: 767px) {

  .pay-image {
    width: 60px;
   
  }
  

  .select-image {
    margin-right: 10px;
    height:20px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}


.img-pile {
  padding-block: 30px;

}

.img-pile img{
  width: 190px;
}
}

.font_100x {
  font-size: 80px;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -55%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    grid-gap: 10px;
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-gap: 15px;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 0%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}
.font_100x {
  font-size: 80px;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -55%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    grid-gap: 10px;
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-gap: 15px;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 0%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}
.font_42x{
  font-size: 42px;
}

.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
  }

  .img-contain{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-pile{
    padding-block: 50px;
  }
  /* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */
  
  .radio-button.selected {
    border-color: #007bff; /* Set the desired color for the outline */
  }

  .label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pay-image {
    /* width: 120px; */
    padding: 5px;
    height: 70px;
  }
  .select-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .select-image {
    padding: 5px;
    height:40px;
    text-align: left;
  }
  
  .pay-description {

    padding-top: 30px;
    font-size: 15px;
    text-align: center;
  }
  
  .selected-opt {
    border: 2px solid #0078E5; /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    height: 70px;
    /* width: 120px; */
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: var(--body_color) !important;
    border-radius: 2px !important;
    border: none;
    background-color: var(--body_background) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--border-color) !important;

  }

  .select-input{
    min-width: 330px !important;
  }

  .drop-image {
    width: 40px;
    margin-right: 10px;
  }

  .drop2-image {
    width: 35px;
    margin-right: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: var(--body_color) !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    background-color: var(--body_background) !important;
    color: var(--body_color) !important;
  }

  .hive-img{
    width: 105px;
    height: 75px;
  }

  .hive-img:hover{
    cursor: pointer;
    transform: scale(1.2);
  }

  .text-color{
    color:#333333
  }

@media (max-width: 767px) {

  .pay-image {
    width: 60px;
   
  }
  

  .select-image {
    margin-right: 10px;
    height:20px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}


.img-pile {
  padding-block: 30px;

}

.img-pile img{
  width: 190px;
}
}

.font_42x{
  font-size: 42px;
}

.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
  }

  .img-contain{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-pile{
    padding-block: 50px;
  }
  /* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */
  
  .radio-button.selected {
    border-color: #007bff; /* Set the desired color for the outline */
  }

  .label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pay-image {
    /* width: 120px; */
    padding: 5px;
    height: 70px;
  }
  .select-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .select-image {
    padding: 5px;
    height:40px;
    text-align: left;
  }
  
  .pay-description {

    padding-top: 30px;
    font-size: 15px;
    text-align: center;
  }
  
  .selected-opt {
    border: 2px solid #0078E5; /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    height: 70px;
    /* width: 120px; */
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: var(--body_color) !important;
    border-radius: 2px !important;
    border: none;
    background-color: var(--body_background) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--border-color) !important;

  }

  .select-input{
    min-width: 330px !important;
  }

  .drop-image {
    width: 40px;
    margin-right: 10px;
  }

  .drop2-image {
    width: 35px;
    margin-right: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: var(--body_color) !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    background-color: var(--body_background) !important;
    color: var(--body_color) !important;
  }

  .hive-img{
    width: 105px;
    height: 75px;
  }

  .hive-img:hover{
    cursor: pointer;
    transform: scale(1.2);
  }

  .text-color{
    color:#333333
  }

@media (max-width: 767px) {

  .pay-image {
    width: 60px;
   
  }
  

  .select-image {
    margin-right: 10px;
    height:20px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}


.img-pile {
  padding-block: 30px;

}

.img-pile img{
  width: 190px;
}
}

.font_42x{
  font-size: 42px;
}

.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
  }

  .img-contain{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-pile{
    padding-block: 50px;
  }
  /* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */
  
  .radio-button.selected {
    border-color: #007bff; /* Set the desired color for the outline */
  }

  .label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pay-image {
    /* width: 120px; */
    padding: 5px;
    height: 70px;
  }
  .select-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .select-image {
    padding: 5px;
    height:40px;
    text-align: left;
  }
  
  .pay-description {

    padding-top: 30px;
    font-size: 15px;
    text-align: center;
  }
  
  .selected-opt {
    border: 2px solid #0078E5; /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    height: 70px;
    /* width: 120px; */
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: var(--body_color) !important;
    border-radius: 2px !important;
    border: none;
    background-color: var(--body_background) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--border-color) !important;

  }

  .select-input{
    min-width: 330px !important;
  }

  .drop-image {
    width: 40px;
    margin-right: 10px;
  }

  .drop2-image {
    width: 35px;
    margin-right: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: var(--body_color) !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    background-color: var(--body_background) !important;
    color: var(--body_color) !important;
  }

  .hive-img{
    width: 105px;
    height: 75px;
  }

  .hive-img:hover{
    cursor: pointer;
    transform: scale(1.2);
  }

  .text-color{
    color:#333333
  }

@media (max-width: 767px) {

  .pay-image {
    width: 60px;
   
  }
  

  .select-image {
    margin-right: 10px;
    height:20px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}


.img-pile {
  padding-block: 30px;

}

.img-pile img{
  width: 190px;
}
}

.font_100x {
  font-size: 80px;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -55%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    grid-gap: 10px;
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-gap: 15px;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 0%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}
.discount_hero_root {
  position: relative;
}

.discount_hero_star_overlay {
  display: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 410px;
  z-index: 1;
}

.discount_hero_star_overlay img {
  width: 100%;
  z-index: 1;
}
.discount_hero_text_container {
  position: absolute;
  padding: 50px;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.discount_hero_text {
  max-width: 600px;
  margin: auto;
  font-size: 26px;
  color: #000;
  text-align: center;
}
.lucky_day_text {
  display: block;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
}
.percentOff {
  display: block;
  font-weight: bolder;
  font-size: 102px;
  text-transform: uppercase;
  margin: -20px;
}

.when_you_text {
  font-size: 38px;
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab-item img {
  width: 50px;
  height: 50px;
}

.tab-text {
  text-align: center;
}

.top {
  font-weight: bold;
}

.bottom {
  font-size: 0.8em;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap; /* Add flex-wrap property */
  max-width: 1000px;
  margin: 50px auto 0px;
}
.tabs-container div {
  width: 170px;
  margin: auto;
}

.all-cards-styling {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 1000px;
  margin: auto;
  align-items: stretch;
}

@media screen and (max-width: 320px) {
  .all-cards-styling {
    grid-template-columns: 1fr; /* Display one card per row for screens with a width of 320px or smaller */
  }
  .discount_hero_star_overlay {
    height: 467px;
  }
  .percentOff {
    font-size: 58px;
    margin: -11px;
  }
  .discount_hero_text {
    font-size: 22px;
  }
  .when_you_text {
    font-size: 30px;
  }
}

/* fixing it */
/* @media screen and (min-width: 390px) { */
/* @media screen and (min-width: 390px) and (max-width: 768px) { */
@media screen and (max-width: 768px) {
  .all-cards-styling {
    grid-template-columns: 1fr;
  }
  .discount_hero_star_overlay {
    height: 467px;
  }
  .percentOff {
    font-size: 58px;
    margin: -11px;
  }
  .discount_hero_text {
    font-size: 22px;
  }
  .when_you_text {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .all-cards-styling {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .tab-item {
    display: block;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
nav {
  position: fixed;
  z-index: 99;
  width: 100%;

  background: #000;
}
nav .wrapper {
  position: relative;

  padding: 0px 100px;
  height: 70px;
  line-height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  text-decoration: none;
}
.wrapper .logo a img {
  transition: all 0.2s linear;
}
.wrapper .logo a img:hover {
  transform: scale(1.1);
}
.wrapper .nav-links {
  display: inline-flex;
  margin: 0;
}
.nav-links li {
  list-style: none;
}

.nav-links li.main {
  padding: 9px 15px;
}
.nav-links li a {
  color: #bfbfbf;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  /* padding: 9px 15px; */

  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  /* background: #3a3b3c; */
  color: #fff;
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.2s ease;
  /* top: 70px; */
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
}
.nav-links li:hover .backdrop {
  display: block;
  visibility: visible;
  opacity: 1;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  top: 70px;
  z-index: -1; /* Set z-index below .mega-box */
  display: none; /* Initially hidden */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
  transition-delay: 0.2s; /* Add a delay of 0.2 seconds */
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  background: #161617;
  height: auto;
  z-index: 1111111;
  padding: 0 30px 50px 0;
  top: 70px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease;
  /* top: 70px; */
  transition-delay: 0.3s;
}

.mega-box .content {
  /* background: white; */
  /* max-width: 1200px;
  margin: auto; */
  height: auto;

  padding: 25px 20px;
  padding-left: 210px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); */
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 35px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  font-size: 12px;
  font-weight: 500;
}
.content .row .mega-links {
  /* margin-left: -40px; */
  /* border-left: 1px solid rgba(255, 255, 255, 0.09); */
}
.row .mega-links li {
  /* padding: 0 20px; */
}

.row .mega-links li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 12px;
  display: block;
}
.dark_color {
  color: #434343 !important;
}
.dark_color:hover {
  color: #2d2d2d !important;
}
.row .mega-links.main li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.secondary_header_root {
  position: fixed;
  top: 70px;
  background: white;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 1000;
}
.secondary_header_content {
  width: 100%;
  max-width: 1900px;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: stretch;
}
.secondary_header_content_item {
  display: flex;
  min-width: 145px;
  margin: 0px 2.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
/* .secondary_header_content_item:hover,
.secondary_header_content_item.active {
  border-bottom: 2px solid grey;
} */
.secondary_header_content_img_container {
  width: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 05px;
}
.secondary_header_content_img_container:hover
  .secondary_header_content_img_container
  img {
  transform: scale(1.1);
}
.secondary_header_content_img_container img {
  height: 32px;
}

.link_active {
  color: white !important;
}
.bee-hexagon {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  -webkit-clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
          clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
}
.elipse-img {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  -webkit-clip-path: ellipse(30% 52% at 50% 50%);
          clip-path: ellipse(30% 52% at 50% 50%);
  transform: rotate(41deg);
  /* margin: 0 auto; */
  overflow: hidden;
}
.elipse-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.bee-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}
@media screen and (max-width: 1150px) {
  nav .wrapper {
    padding: 0 30px;
  }
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #Exchange:checked ~ .drop-menu,
  #Lotto:checked ~ .mega-box,
  #Meme:checked ~ .mega-box,
  #Meme:checked ~ .mega-box,
  #Hive:checked ~ .mega-box,
  #Tokens:checked ~ .mega-box,
  #Academy:checked ~ .mega-box,
  #Swap:checked ~ .mega-box,
  #Shop:checked ~ .mega-box,
  #Affiliate:checked ~ .mega-box,
  #Elite\ Club:checked ~ .mega-box,
  #Company:checked ~ .mega-box,
  #Exchange:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
  .secondary_header_content {
    justify-content: flex-start;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}

.dark_mode {
  /* margin-top: -67px; */
  /* margin-left: 10px; */
  width: 22px;
  height: 22px;
}

.dark_mode_label {
  width: 0;
  height: 0;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  /* box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4); */
  cursor: pointer;
  /* transition: 0.3s; */
}

.dark_mode_label:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  /* top: 3px; */
  /* left: 2px; */
  /* background: linear-gradient(180deg, #ffcc89, #d8860b); */
  /* background: linear-gradient(180deg,#e8dfdf, #adadad); */
  background-image: url("/static/media/dark 2.5090370f.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background-image: url("/static/media/light 2.35e4658b.svg");
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  /* left: 42px; */
  /* transform: translateX(-100%); */
  /* background: linear-gradient(180deg, #777, #3a3a3a); */
  background-image: url("/static/media/light 2.35e4658b.svg");
  background-size: contain;
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

.container {
  /* Your container styles */
}

.header {
  /* Background image styles */
  height: 87px;
  width: 100%;
  background-image: url(/static/media/bg.8cf48c9a.png);
  background-size: cover;
  margin-top: 70px;
}
.main-heading {
  text-align: center;
  font-weight: bold;
  font-size: 100px;
  margin: 100px auto 25px auto;
}
.category-section,
.project-section {
  /* Your section styles */
  padding: 20px;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.card-container .card {
  background: none;
  border: none;
  min-width: 300px;
  height: auto;
  min-height: 100px;
}
.card-container .card a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-align: center;
}
.project-container {
  /* Flex container styles */
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.project-card {
  /* Card styles */
  max-width: 410px;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  /* Adjust width and margin as needed for responsiveness */
}
.hoverImage {
  transition: all 0.2s linear;
}
.hoverImage:hover {
  transform: scale(1.05);
}
.card img {
  height: 200px;
  transition: all 0.2s linear;
}
.card img:hover {
  transform: scale(1.05);
}
.project-card img {
  /* Image styles */
  height: 200px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.project-card img:hover {
  transform: scale(1.05);
}
.card h2,
.project-card h2 {
  /* Heading styles */
}

.project-card p {
  /* Description styles */
  margin-bottom: 20px;
}

.learn-more-button {
  /* Button styles */
  display: block;
  width: 100%;
  background-color: #f8c229;
  color: black;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border: none;
}
.learn-more-button:hover {
  background-color: #ffd000;
  color: black;
}
@media (max-width: 900px) {
  .main-heading {
    font-size: 62px;
  }
}

.font_100x {
  font-size: 80px;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_200x {
  font-size: 200px;
}

.font_47x {
  font-size: 47px;
}

.font_34x {
  font-size: 34px;
}

.font_37x {
  font-size: 37px;
}

.font_22x {
  font-size: 22px;
}

.font_16x {
  font-size: 16px;
}

.font_11x {
  font-size: 11px;
}

.air-drop {
  color: var(--dark_text);
  /* margin-left: 2% ; */
  margin-top: 5%;
  width: 100%;
  padding-left: 50%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-top: 110px;
  padding-bottom: 220px;
  background-color: transparent;
}

.est-val {
  color: var(--dark_text);
  /* margin-left: 5% ; */
  margin-top: -34%;
  padding-right: 3.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 200px;
  background-color: transparent;
}

.timer {
  display: flex;
  justify-content: end;
}

.timer-link {
  margin-left: 27.4%;
  display: flex;
  justify-content: start;
}

.what-inex {
  color: var(--dark_text);
  margin-left: 5%;
  padding-right: 3%;
  margin-top: -8%;
  padding-left: 50%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding-bottom: 220px;
  background-color: transparent;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 220px;
  background-color: transparent;
}

.req {
  color: var(--dark_text);
  margin-left: 5%;
  margin-top: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.req-desc {
  margin-right: -5%;
  width: 80%;
}

.ind-req-desc {
  margin-left: 14%;
}

.eagle-img {
  display: flex;
  justify-content: start;
  margin-top: -55%;
}

@media (max-width: 767px) {
  .air-drop {
    color: var(--dark_text);
    margin-top: 0%;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .est-val {
    color: var(--dark_text);
    margin-top: -5%;
    padding-right: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .timer {
    display: flex;
    justify-content: end;
  }

  .timer-link {
    margin-left: 27.4%;
    display: flex;
    justify-content: start;
  }

  .what-inex {
    margin-left: 0%;
    padding-right: 0%;
    margin-top: -8%;
    padding-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 220px;
    color: var(--dark_text);
    grid-gap: 10px;
    gap: 10px;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 220px;
    background-color: transparent;
  }

  .req {
    color: var(--dark_text);
    margin-left: 0%;
    margin-top: 12%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    background-color: transparent;
  }

  .ind-req {
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-gap: 15px;
    gap: 15px;
  }

  .ind-req-desc {
    margin-left: 0%;
    text-align: left;
  }

  .ind-req img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto !important;
  }
  .req-desc {
    margin-right: -0%;
    margin-top: 12%;
    width: 100%;
  }

  .req-img {
    width: 100%;
  }
  .eagle-img {
    display: flex;
    justify-content: start;
    margin-top: 0%;
  }
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(var(--disabled-btn), 0.26) !important;
  background-color: rgba(var(--disabled-btn), 0.12) !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--body_color) !important;
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}
.font_42x{
  font-size: 42px;
}

.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
  }

  .img-contain{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-pile{
    padding-block: 50px;
  }
  /* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */
  
  .radio-button.selected {
    border-color: #007bff; /* Set the desired color for the outline */
  }

  .label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pay-image {
    /* width: 120px; */
    padding: 5px;
    height: 70px;
  }
  .select-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .select-image {
    padding: 5px;
    height:40px;
    text-align: left;
  }
  
  .pay-description {

    padding-top: 30px;
    font-size: 15px;
    text-align: center;
  }
  
  .selected-opt {
    border: 2px solid #0078E5; /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    height: 70px;
    /* width: 120px; */
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: var(--body_color) !important;
    border-radius: 2px !important;
    border: none;
    background-color: var(--body_background) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--border-color) !important;

  }

  .select-input{
    min-width: 330px !important;
  }

  .drop-image {
    width: 40px;
    margin-right: 10px;
  }

  .drop2-image {
    width: 35px;
    margin-right: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: var(--body_color) !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    background-color: var(--body_background) !important;
    color: var(--body_color) !important;
  }

  .hive-img{
    width: 105px;
    height: 75px;
  }

  .hive-img:hover{
    cursor: pointer;
    transform: scale(1.2);
  }

  .text-color{
    color:#333333
  }

@media (max-width: 767px) {

  .pay-image {
    width: 60px;
   
  }
  

  .select-image {
    margin-right: 10px;
    height:20px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}


.img-pile {
  padding-block: 30px;

}

.img-pile img{
  width: 190px;
}
}

