.section3-container {
  display: flex;
  padding: 20px;
  margin: 20px;
}

.data-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  cursor: pointer;
}

.label-container {
  font-family: AvenirHeavy, serif;
  font-size: 35px;
  font-weight: 600;
  text-align: right;
  padding: 16px 0;
  color: var(--section3-unselected);
  line-height: 35px;
}

.selected-data {
  padding: 20px 0 20px 20px;
}

.selected-data-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selected-circle {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  color: #006dff;

  border: 3px solid;
  border-radius: 50%;
}

.selected-header {
  font-family: AvenirHeavy, serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  /* color: #5a5858; */
  color: var(--body_color);
}

.help-text {
  /* color: #5a5858; */
  color: var(--body_color);
  font-family: AvenirRegular, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 30px;
  padding-top: 15px;
}

.data-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 50px;
}

.selected {
  /* color: #5f5f5f; */
  color: var(--body_color);
}

.label-container:hover {
  /* color: #5f5f5f; */
  color: var(--body_color);
}

.actionLink {
  font-family: AvenirRegular, serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  color: #11be6a;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton {
  min-width: 260px;
  font-size: 20px;
  font-weight: 400;
  font-family: AvenirRegular, serif;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #11be6a;
  border-radius: 10px;
  color: #5a5858;
  width: fit-content;
}

@media (max-width: 767px) {
  .section3-container {
    flex-direction: column;
  }

  .section3-container > div {
    width: 100%;
  }

  .selected-data,
  .data-right-container {
    padding-left: 0;
  }

  .label-container {
    text-align: left;
  }
}
